import gql from 'graphql-tag';

const signinMutation = gql`
  mutation Signin($username: String!, $password: String!) {
    login(input: {
      username: $username,
      password: $password,
    }) {
      accessToken: access_token
      refreshToken: refresh_token
      expiresIn: expires_in
      tokenType: token_type
      tiledeskJwt: tiledesk_jwt
      user {
        id
        name
        email
        gender
        avatar {
          url
          default
          default2x
          default3x
        }
        plan {
          startDate: start_date
        }
      }
    }
  }
`;

const signupMutation = gql`
  mutation Signup(
    $name: String!,
    $surname: String!,
    $email: String!,
    $password: String!,
    $passwordCheck: String!,
    $privacy: Boolean!,
    $marketing: Boolean,
  ) {
    register(input: {
      name: $name,
      last_name: $surname,
      email: $email,
      password: $password,
      password_confirmation: $passwordCheck,
      privacy_check: $privacy,
      marketing_check: $marketing,
    }) {
      status
    }
  }
`;

const forgotPasswordMutation = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status,
      message
    }
  }
`;

const updateForgottenPasswordMutation = gql`
  mutation UpdateForgottenPassword(
    $email: String!,
    $token: String!,
    $password: String!,
    $passwordConfirmation: String!,
  ) {
    updateForgottenPassword(input: {
      email: $email,
      token: $token,
      password: $password,
      password_confirmation: $passwordConfirmation,
    }) {
      status,
      message
    }
  }
`;

const updateUserProfileMutation = gql`
  mutation UpdateUserProfile(
    $name: String,
    $surname: String,
    $email: String,
    $password: String,
    $gender: UserGender,
    $birthdate: Date,
    $height: Float,
    $weight: Float,
  ) {
    updateUser(
      name: $name,
      last_name: $surname,
      email: $email,
      gender: $gender,
      birthdate: $birthdate,
      height: $height,
      weight: $weight,
      password: $password,
    ) {
      id
    }
  }
`;

const refreshTokenMutation = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(input: { refresh_token: $refreshToken }) {
      accessToken: access_token
      refreshToken: refresh_token
      expiresIn: expires_in
      tokenType: token_type
    }
  }
`;

export {
  forgotPasswordMutation,
  refreshTokenMutation,
  updateForgottenPasswordMutation,
  updateUserProfileMutation,
  signinMutation,
  signupMutation,
};
