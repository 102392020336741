import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import librawayV2Theme from '../../theme/v2';
import BodyContainer from '../../components/BodyContainer';
import Presentation from './Presentation';
import WhatCanWeDo from './WhatCanWeDo';
import TryMenuBanner from './TryMenuBanner';
import Steps from './Steps';
import Dishes from './Dishes';
import OtherServices from './OtherServices';
import AboutUs from './AboutUs';

const Home = () => (
  <ThemeProvider theme={librawayV2Theme}>
    <BodyContainer backgroundFixed style={{ paddingBottom: 0 }}>
      <Presentation />
      <WhatCanWeDo />
      <TryMenuBanner />
      <Steps />
      <Dishes />
      <OtherServices />
      <AboutUs />
    </BodyContainer>
  </ThemeProvider>
);

export default Home;
