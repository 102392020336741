import { useApolloClient } from '@apollo/react-hooks';
import { useAppContext } from '../../../contexts/AppContext';

export default () => {
  const client = useApolloClient();
  const { authentication: [, setAuthentication], user: [, setUser] } = useAppContext();

  return () => {
    window.tiledesk.logout();
    setAuthentication({});
    setUser({});
    client.resetStore();
  };
};
