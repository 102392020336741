import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import flatMap from 'lodash/flatMap';
import Box from '@material-ui/core/Box';
import { useAppContext } from '../../../contexts/AppContext';
import { DataContext } from '../contexts';
import MobileDrawer from '../../../components/MobileDrawer';
import Button from '../../../components/Button';
import Filters from '../Filters';
import useStyles from './style';

const FiltersDrawer = ({ open, setClose }) => {
  const { t } = useTranslation('recipe');
  const { orderDishesFilters: [checkedFilters] } = useAppContext();
  const { loading, data } = useContext(DataContext);
  const dataCount = data ? data.length : 0;
  const hasFilters = flatMap(checkedFilters, (value) => value).length;
  const {
    header,
    filterContainer,
    button,
  } = useStyles();

  const renderButtonText = () => {
    if (loading) { return `${t('common:loading')}...`; }
    if (hasFilters) {
      if (dataCount === 0) {
        return t('no-result');
      }

      return t('displays-results', { count: dataCount });
    }

    return t('common:close');
  };

  return (
    <MobileDrawer
      open={open}
      onClose={setClose}
    >
      <Box
        className={header}
        justifyContent="center"
        alignItems="flex-end"
      >
        {t('filter-by')}
      </Box>

      <Box className={filterContainer}>
        <Filters />
      </Box>

      <Button
        fat
        // stretched="90%"
        filled={!!(hasFilters && (data && dataCount > 0))}
        className={button}
        onClick={setClose}
        disabled={loading}
      >
        {renderButtonText()}
      </Button>
    </MobileDrawer>
  );
};

FiltersDrawer.propTypes = {
  open: PropTypes.bool,
  setClose: PropTypes.func.isRequired,
};

FiltersDrawer.defaultProps = {
  open: false,
};

export default FiltersDrawer;
