import CartIcon from '../../components/CartIcon';
import NotificationIcon from '../../components/NotificationIcon';
import { ReactComponent as DashboardIcon } from '../../assets/icons/user.svg';
import { ReactComponent as OrderDishesIcon } from '../../assets/icons/orderDishes.svg';
import { ReactComponent as ShoppingListIcon } from '../../assets/icons/shoppingList.svg';
import { ReactComponent as DishesIcon } from '../../assets/icons/piatti.svg';
import { ReactComponent as RecipesIcon } from '../../assets/icons/ricette.svg';
import { ReactComponent as PlanIcon } from '../../assets/icons/piano.svg';
// import { ReactComponent as DiaryIcon } from '../../assets/icons/diario.svg';
import { ReactComponent as PointsIcon } from '../../assets/icons/punti.svg';

const navigationItems = [{
  name: 'about-us',
  justFooter: true,
}, {
  name: 'how-it-works',
  justAuthenticated: false,
}, {
  name: 'my-plan',
  link: 'dashboard',
  icon: PlanIcon,
  justAuthenticated: true,
}, {
  name: 'dishes',
  icon: DishesIcon,
}, {
  name: 'recipes',
  icon: RecipesIcon,
// }, {
//   name: 'diary',
//   link: 'dashboard/diary',
//   icon: DiaryIcon,
//   justAuthenticated: true,
}, {
  name: 'points',
  icon: PointsIcon,
  justAuthenticated: true,
  disabled: true,
}];

const userItems = [
  { name: 'orders' },
  { name: 'shopping-lists' },
  { name: 'favorites', disabled: true },
  { name: 'profile', divider: true },
  { name: 'contacts', disabled: true },
  { name: 'faq', divider: true, disabled: true },
];

const quickItems = [{
  name: 'dishes',
  render: OrderDishesIcon,
  justOnMobile: true,
}, {
  name: 'dashboard',
  authenticated: true,
  items: [
    { name: 'today' },
    { name: 'week' },
    { name: 'diary' },
    { name: 'tracking', disabled: true },
    { name: 'map', disabled: true },
    { name: 'fitness', disabled: true },
  ],
  render: DashboardIcon,
}, {
  name: 'cart',
  link: 'checkout',
  render: CartIcon,
}, {
  name: 'shopping-list',
  link: 'shopping-lists',
  render: ShoppingListIcon,
  authenticated: true,
}, {
  name: 'notifications',
  render: NotificationIcon,
  selfHandleClick: true,
  authenticated: true,
  hideOnMobile: true,
}];

export {
  navigationItems,
  quickItems,
  userItems,
};
