import { useMutation } from '@apollo/react-hooks';
import { updateUserProfileMutation } from '../../mutations/user';

export default () => {
  const [updateUserProfile, {
    loading,
    data: { updateUser } = {},
    errors,
  }] = useMutation(updateUserProfileMutation);

  const { status, message } = updateUser || {};

  return [updateUserProfile, { loading, data: { status, message }, errors }];
};
