import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import useDateFns from '../../hooks/useDateFns';
import useReadNotification from '../../graphql/hooks/notifications/useReadNotification';
import NotificationDot from '../NotificationDot';
import useStyles from './style';

const NotifyItem = forwardRef(({
  id,
  title,
  message,
  readAt,
  createdAt,
  handleClose,
}, ref) => {
  const { titleStyle, messageStyle, dateStyle } = useStyles();
  const { formatDistanceToNow } = useDateFns();
  const [readNotification] = useReadNotification();

  const handleNotifyClick = () => {
    window.tiledesk.open();
    handleClose();
    readNotification({ variables: { id } });
  };

  const renderTitle = (
    <Typography color="primary" className={titleStyle}>{title}</Typography>
  );

  const renderMessage = (
    <>
      <Typography className={messageStyle}>{message}</Typography>
      <Typography className={dateStyle}>
        <span>{formatDistanceToNow(new Date(createdAt.replace(' ', 'T')), { addSuffix: true })}</span>
      </Typography>
    </>
  );

  return (
    <ListItem
      button
      onClick={handleNotifyClick}
      ref={ref}
    >
      <ListItemText
        disableTypography
        primary={renderTitle}
        secondary={renderMessage}
      />
      <ListItemSecondaryAction>
        <NotificationDot hidden={!!readAt} />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

NotifyItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  readAt: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
};

NotifyItem.defaultProps = {
  title: null,
  message: null,
  readAt: null,
  handleClose: () => {},
};

export default NotifyItem;
