import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAppContext } from '../../contexts/AppContext';
import { useCartContext } from '../../contexts/CartContext';
import useCreateOrder from '../../graphql/hooks/order/useCreateOrder';
import { useUserInfoContext } from './UserInfoContext';
import { formatMoney } from '../../libraries/helpers';
import Divider from '../../components/Divider';
import Button from '../../components/Button';
import CartSummaryItem from './CartSummaryItem';
import OrderSummaryPartials from './OrderSummaryPartials';
import useStyles from './style';
import PrivacyCheckbox from './PrivacyCheckbox';

const OrderSummary = () => {
  const { t } = useTranslation('order');
  const { summaryContainer, summaryInnerContainer, totalTypography } = useStyles();
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const { authenticated } = useAppContext();
  const [privacyPrivate, setPrivacyPrivate] = useState(authenticated);
  const [privacyCommercial, setPrivacyCommercial] = useState(false);
  const [{
    cartItems,
    cartPrice,
    itemsCount,
    orderId,
    getCoupon,
  }, { updateOrderId }] = useCartContext();
  const [createOrder, { data, errors }] = useCreateOrder();
  const {
    addressState: [address],
    deliveryDateState: { choosenState: [date] },
    deliveryContactState: [deliveryContact],
  } = useUserInfoContext();

  if (data && data.createOrder && !errors) {
    const stripe = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);
    const { session: sessionId, order: { id: orderIdLocal } } = data.createOrder;
    if (orderId !== orderIdLocal) {
      updateOrderId(orderIdLocal);
    }

    stripe.redirectToCheckout({ sessionId }).then(() => {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
  }

  const handlePrivacyChange = (type) => ({ target: { checked } }) => {
    if (type === 'private') {
      setPrivacyPrivate(checked);
    } else if (type === 'commercial') {
      setPrivacyCommercial(checked);
    }
  };

  const handleConfirm = () => {
    createOrder({
      variables: {
        id: orderId,
        coupon: getCoupon('id'),
        cart: cartItems.map(({ id, quantity }) => ({ id, quantity })),
        privacyPrivate,
        privacyCommercial,
        shippingAddress: {
          first_name: address.name,
          last_name: address.surname,
          address: `${address.address} ${address.number}`,
          zip: address.zip,
          city: address.city,
        },
        shippingDate: { date },
        shippingContact: {
          first_name: deliveryContact.name,
          last_name: deliveryContact.surname,
          email: deliveryContact.email,
          phone: deliveryContact.phone,
          courier_note: deliveryContact.courierNote,
        },
      },
    });
  };

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={summaryContainer}
    >
      <Grid item>
        <Grid
          container
          className={summaryInnerContainer}
          direction="column"
          spacing={isSmallView ? 2 : 0}
        >
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h3">
                  {t('n-dishes', { count: itemsCount })}
                </Typography>
              </Grid>

              {cartItems.map(({
                id,
                dishId,
                title,
                grams,
                unitPrice,
                quantity,
                image,
              }) => (
                <Grid item style={{ maxHeight: 82 }}>
                  <CartSummaryItem
                    key={id}
                    id={id}
                    dishId={dishId}
                    title={title}
                    grams={grams}
                    unitPrice={unitPrice}
                    quantity={quantity}
                    image={image}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item>
            <Divider color="grey" />
          </Grid>

          <Grid item>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <OrderSummaryPartials />
            </Grid>
          </Grid>

          <Grid item>
            <Divider color="grey" />
          </Grid>

          <Grid item container justify="space-between">
            <Grid item>
              <Typography variant="h3" className={totalTypography}>
                {t('total')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3">
                {formatMoney(cartPrice)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!authenticated && (
        <>
          <Grid item>
            <PrivacyCheckbox
              label={t('privacy-private')}
              checked={privacyPrivate}
              onChange={handlePrivacyChange('private')}
            />
          </Grid>

          <Grid item>
            <PrivacyCheckbox
              label={t('privacy-commercial')}
              checked={privacyCommercial}
              onChange={handlePrivacyChange('commercial')}
            />
          </Grid>
        </>
      )}

      <Grid item>
        <Grid container justify="center">
          <Button
            filled
            disabled={!privacyPrivate}
            onClick={handleConfirm}
          >
            {t('proceed-to-purchase')}
          </Button>
        </Grid>
      </Grid>

      <Grid item style={{ marginBottom: 48 }}>
        <Typography variant="caption">
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('order-note') }}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
