import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { getImagesSet } from '../../../libraries/helpers';
import Button from '../../../components/Button';
import Dialog from '../../../components/Dialog';
import Divider from '../../../components/Divider';
import { ReactComponent as ChangeIcon } from '../../../assets/icons/change.svg';
import useStyles from '../style';

const Variations = ({ variations }) => {
  const { t } = useTranslation('dashboard');
  const { variationImage, icon } = useStyles();
  const { useClasses } = useTheme();
  const { capitalize, bold } = useClasses();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => { setModalOpen(true); };
  const handleModalClose = () => { setModalOpen(false); };

  const handleVariationClick = (id) => () => {
    history.push(`/recipes/${id}`);
  };

  /* eslint-disable react/prop-types */
  const renderVariation = ({
    id,
    image,
    title,
    foods,
  }) => (
    <ListItem button onClick={handleVariationClick(id)} key={id}>
      <ListItemAvatar>
        {image.length ? (
          <Avatar
            variant="rounded"
            src={image[0].thumbnail}
            srcSet={getImagesSet(image[0], 'thumbnail')}
            className={variationImage}
          />
        ) : null}
      </ListItemAvatar>
      <ListItemText secondary={foods.map(({ name }) => name).join(' + ')}>
        <Typography className={classNames(capitalize, bold)}>
          {title}
        </Typography>
      </ListItemText>
    </ListItem>
  );
  /* eslint-enable react/prop-types */

  return (
    <>
      <Button
        fat
        fitted
        onClick={handleModalOpen}
      >
        <ChangeIcon className={icon} />
        {t('variations')}
      </Button>

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        closeButton={false}
      >
        <List disablePadding dense>
          {variations.map(renderVariation)}
        </List>
        <Divider />
        <Button disabled fitted>
          {t('recipe:suggest-change')}
        </Button>
      </Dialog>
    </>
  );
};

Variations.propTypes = {
  variations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Variations;
