import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { UserInfoProvider } from './UserInfoContext';
import BodyContainer from '../../components/BodyContainer';
import BackButton from '../../components/BackButton';
import PaymentLanding from '../PaymentLanding';
import InnerContent from './InnerContent';
import useStyles from './style';

const Order = () => {
  const { t } = useTranslation(['order', 'dish']);
  const { boxContainer } = useStyles();
  const { response } = useParams();
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));

  return (
    <BodyContainer style={isSmallView ? { paddingBottom: 0 } : null}>
      <UserInfoProvider>
        <Container disableGutters={isSmallView}>
          <Grid container spacing={4}>
            {!isSmallView && (
              <Grid item xs={12} md={2}>
                {!response && <BackButton goTo="/dishes">{t('dish:back-to-dish')}</BackButton>}
              </Grid>
            )}
            <Grid item xs={12} md={8}>
              <Grid container className={boxContainer}>
                {!response ? <InnerContent /> : <PaymentLanding />}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </UserInfoProvider>
    </BodyContainer>
  );
};

export default Order;
