import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useResponsive from '../../hooks/useResponsive';
import ComingSoon from '../../components/ComingSoon';
import chat from '../../assets/what_can_we_do/chat.svg';
import dishes from '../../assets/what_can_we_do/piatti.svg';
import community from '../../assets/what_can_we_do/community.svg';
import planes from '../../assets/what_can_we_do/piani.svg';
import useStyles from './style';

const data = [{
  label: 'dishes',
  image: dishes,
}, {
  label: 'planes',
  image: planes,
  comingSoon: true,
}, {
  label: 'chat',
  image: chat,
  comingSoon: true,
}, {
  label: 'community',
  image: community,
  comingSoon: true,
}];

const WhatCanWeDo = () => {
  const { t } = useTranslation('home');
  const { isSmallView } = useResponsive();
  const { whatCanWeDoContainer, svgImage } = useStyles();

  return (
    <Container className={whatCanWeDoContainer}>
      <Grid container spacing={isSmallView ? 4 : 6}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t('what-can-we-do')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify={isSmallView ? 'center' : 'space-between'}
            alignItems="flex-start"
            spacing={isSmallView ? 2 : 4}
          >
            {data.map(({ label, image, comingSoon }) => (
              <Grid key={label} item xs={12} md={3}>
                <ComingSoon disable={!comingSoon}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <img src={image} className={svgImage} alt="What can we do item" />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" align="center">
                        {t(`item-${label}`)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ComingSoon>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WhatCanWeDo;
