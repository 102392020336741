import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GridList from '@material-ui/core/GridList';
import Grid from '@material-ui/core/Grid';
import { DataContext } from './contexts';
import Button from '../../components/Button';
import Placeholder from '../../components/Placeholder';
import Loading from '../../components/Loading';
import Card from '../../components/Card';

const Content = () => {
  const { t } = useTranslation('orderDishes');
  const { spacing, breakpoints } = useTheme();
  const isExtraSmallView = useMediaQuery(breakpoints.down('xs'));
  const history = useHistory();
  const {
    dataType,
    data: {
      data,
      loadingFetchMore,
      fetchMore,
      hasNextPage,
    },
  } = useContext(DataContext);

  const checkFetchMoreIsVisible = (isVisible) => {
    if (isVisible && hasNextPage) {
      fetchMore();
    }
  };

  const handleClick = (id) => () => { history.push(`/${dataType}/${id}`); };

  const renderLoading = (
    <Loading size="small">
      {`${t('common:loading')}...`}
    </Loading>
  );

  /* eslint-disable react/prop-types */
  const renderGridCard = ({
    node: {
      id,
      title,
      image,
      price,
      discountedPrice,
      comments,
      avgVote,
      sizes,
      foods,
    },
  }) => {
    const { total: commentsCount } = (comments && comments.pageInfo) || {};

    return (
      <Grid
        key={id}
        container
        justify="center"
      >
        <Card
          badge={discountedPrice ? {
            label: t('badge-sale'),
            color: 'primary.dark',
          } : {}}
          cartInfo={{
            id,
            title,
            price,
            discountedPrice,
            sizes,
            image,
          }}
          title={title}
          type={dataType}
          image={image}
          commentsCount={commentsCount}
          valuation={avgVote}
          foods={foods}
          handleClick={handleClick(id)}
        />
      </Grid>
    );
  };

  if (data) {
    return (
      <>
        {data.length ? (
          <GridList
            cellHeight="auto"
            spacing={spacing(4)}
            cols={isExtraSmallView ? 2 : 3}
          >
            {data.map(renderGridCard)}
          </GridList>
        ) : (
          <Placeholder text={t('no-element')} />
        )}

        {hasNextPage && (
          <VisibilitySensor
            partialVisibility
            offset={{ top: 300 }}
            onChange={checkFetchMoreIsVisible}
          >
            <Grid item container xs={12} justify="center">
              <Button
                disabled={loadingFetchMore}
                onClick={fetchMore}
                style={{ marginTop: 50 }}
              >
                {loadingFetchMore
                  ? renderLoading
                  : t('load-more')}
              </Button>
            </Grid>
          </VisibilitySensor>
        )}
      </>
    );
  }

  return null;
};

export default Content;
