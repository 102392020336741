import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import useGetDiets from '../../../graphql/hooks/generic/useGetDiets';
import { useAppContext } from '../../../contexts/AppContext';
import RecipeContext from '../contexts';
import DosesContext from './contexts';
import Button from '../../../components/Button';
import Svg from '../../../components/Svg';
import Dialog from '../../../components/Dialog';
import Head from './Head';
import Variations from './Variations';
import Ingredient from './Ingredient';
import ShoppingListDialog from './ShoppingListDialog';
import { ReactComponent as ChangeIcon } from '../../../assets/icons/change.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg';
import useStyles from '../style';

const Ingredients = () => {
  const { t } = useTranslation(['recipe', 'shoppingList']);
  const history = useHistory();
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('md'));
  const [shoppingListDialogOpen, setShoppingListDialogOpen] = useState(false);
  const { diets } = useGetDiets();
  const {
    dietsContainer,
    ingredientsContainer,
    ingredientGrid,
    icon,
    buttonsContainer,
    dietsGrid,
    shoppingListDialog,
  } = useStyles();
  const {
    id,
    composition,
    people,
    variations,
    diets: recipeDiets,
  } = useContext(RecipeContext);
  const dosesState = useState(people || 1);
  const hasVariations = !!variations.length;
  const { authentication: [authentication] } = useAppContext();
  const isAuthenticated = !!authentication.accessToken;

  const openShoppingListDialog = () => {
    if (isAuthenticated) {
      setShoppingListDialogOpen(true);
    } else {
      history.push('/signin');
    }
  };
  const closeShoppingListDialog = () => { setShoppingListDialogOpen(false); };

  /* eslint-disable react/prop-types */
  const renderDiets = ({ id: dietId, label, icon: dietIcon }) => (
    <Grid key={dietId} item>
      <Svg
        size={32}
        title={label}
        url={dietIcon && dietIcon.url}
        active={recipeDiets.findIndex(({ id: recipeDietId }) => recipeDietId === dietId) > -1}
      />
    </Grid>
  );

  // eslint-disable-next-line react/prop-types
  const renderIngredient = ({ ingredient, quantity, unit }) => (
    <Grid
      key={ingredient}
      container
      justify="center"
    >
      <Ingredient
        ingredient={ingredient}
        quantity={quantity}
        unit={unit}
      />
    </Grid>
  );

  return (
    <DosesContext.Provider value={dosesState}>
      <div className={ingredientsContainer}>
        <Grid item md={12}>
          <Head />
        </Grid>
        <Grid
          container
          className={dietsGrid}
          justify="center"
        >
          <Grid
            container
            justify="space-around"
            spacing={1}
            className={dietsContainer}
          >
            {diets && diets.map(renderDiets)}
          </Grid>
        </Grid>
        <GridList
          cellHeight="auto"
          cols={isMobileView ? 1 : 3}
          className={ingredientGrid}
        >
          {composition.map(renderIngredient)}
        </GridList>
        <Grid
          container
          justify="space-around"
          spacing={2}
          className={buttonsContainer}
        >
          <Grid
            item
            container
            justify="center"
            xs={12}
            md={3}
          >
            <Button
              fat
              fitted
              onClick={openShoppingListDialog}
              style={{ letterSpacing: isMobileView ? 0 : 'inherit' }}
            >
              <ListIcon className={icon} />
              {t('add-to-shopping-list')}
            </Button>
          </Grid>
          <Grid
            item
            container
            justify="center"
            xs={12}
            md={3}
          >
            {hasVariations
              ? <Variations variations={variations} />
              : (
                <Button disabled fat fitted fixOpacity>
                  <ChangeIcon className={icon} />
                  {t('suggest-change')}
                </Button>
              )}
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={shoppingListDialogOpen}
        onClose={closeShoppingListDialog}
        className={shoppingListDialog}
        title={t('shoppingList:shopping-list-title')}
      >
        <ShoppingListDialog
          recipeId={id}
          ingredients={composition}
        />
      </Dialog>
    </DosesContext.Provider>
  );
};

export default Ingredients;
