import gql from 'graphql-tag';

const dishFragment = gql`
  fragment MainDishData on Menu {
    id,
    title,
    price,
    discountedPrice: discounted_price,
    sizes {
      id,
      additionalCost: additional_cost,
      dimension,
      grams,
    },
    foods {
      id,
      name,
      image { url },
    },
    image {
      url,
      thumbnail,
      thumbnail2x,
      thumbnail3x,
      default,
      default2x,
      default3x,
    },
  }
`;

const getDishesQuery = gql`
  query Dishes($first: Int, $after: String, $filter: RecipeFilterInput) {
    menus(
      first: $first,
      after: $after,
      filter: $filter,
    ) {
      pageInfo {
        endCursor,
        hasNextPage
      }
      edges {
        node { ...MainDishData }
      }
    }
  }
  ${dishFragment}
`;

const getHomepageDishesQuery = gql`
  query DishesHomepage($after: String) {
    menus(
      first: 8,
      after: $after,
      filter: { random: true },
    ) {
      pageInfo {
        endCursor,
        hasNextPage
      }
      edges {
        node { ...MainDishData }
      }
    }
  }
  ${dishFragment}
`;

const getDishQuery = gql`
  query Dish($dishId: String) {
    menu(id: $dishId) {
      ...MainDishData,
      notes,
      categories { id, name },
      foods {
        id,
        name,
        image { url },
      },
      price,
      discountedPrice: discounted_price,
      sizes {
        id,
        additionalCost: additional_cost,
        dimension,
        grams,
      },
      ingredientTab: ingredient_tab,
      preparationTab: preparation_tab,
      nutritionalTab: nutritional_tab,
      allergensTab: allergens_tab,
      published,
    }
  }
  ${dishFragment}
`;

export {
  getDishQuery,
  getHomepageDishesQuery,
  getDishesQuery,
};
