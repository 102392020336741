import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useAppContext } from '../../contexts/AppContext';
import QuickMenuInlineItem from './QuickMenuInlineItem';

const InlineContainer = styled(Box)(({ theme: { spacing } }) => ({
  '& > * + *': {
    marginLeft: spacing(),
  },
}));

const QuickMenu = ({ quickItems, isMobileView }) => {
  const { authentication: [authentication] } = useAppContext();
  const authenticated = !!authentication.accessToken;

  const toAuthentication = (item) => (authenticated && item.authenticated) || !item.authenticated;
  const toMobile = ({ hideOnMobile, justOnMobile }) => (
    !isMobileView
      ? !justOnMobile
      : !hideOnMobile
  );

  return (
    <InlineContainer>
      {quickItems
        .filter(toAuthentication)
        .filter(toMobile)
        .map((item) => (
          <QuickMenuInlineItem
            key={item.name}
            item={item}
          />
        ))}
    </InlineContainer>
  );
};

QuickMenu.propTypes = {
  quickItems: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  })).isRequired,
  isMobileView: PropTypes.bool,
};

QuickMenu.defaultProps = {
  isMobileView: false,
};

export default QuickMenu;
