import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints, spacing, palette: { primary } }) => ({
  container: {
    marginTop: spacing(8),
  },

  panelContainer: {
    maxWidth: '100%',
    backgroundColor: primary.light,
    paddingTop: spacing(8),
    paddingLeft: spacing(8),
    paddingRight: spacing(8),
    paddingBottom: spacing(16),
    [breakpoints.down('sm')]: {
      padding: spacing(2),
    },
  },

  mobileScroll: {
    minWidth: '100%',
    display: 'flex',
    overflowX: 'scroll',
    justifyContent: 'space-between',
    marginLeft: -spacing(2),
    marginRight: -spacing(2),
    marginBottom: spacing(3),
    paddingLeft: spacing(2),
    paddingBottom: spacing(2),
    '& > * + *': {
      marginLeft: spacing(4),
      [breakpoints.down('md')]: {
        marginLeft: spacing(2),
      },
    },
  },

  innerIcon: {
    marginLeft: spacing(),
  },

  dailyPlanContainer: {
    textAlign: 'center',
    '& > * + *': {
      marginTop: spacing(2),
    },
  },

  tabsButtonRow: {
    position: 'relative',
    display: 'flex',
    overflowX: 'auto',
  },
}));

export default useStyles;
