import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import Zoom from '@material-ui/core/Zoom';
import useReactHookForm from '../../hooks/useReactHookForm';
import useGetRegistry from '../../graphql/hooks/user/useGetRegistry';
import useUpdateUserProfile from '../../graphql/hooks/user/useUpdateUserProfile';
import LocalLoading from '../../components/LocalLoading';
import Input from '../../components/Input';
import InputDropdown from '../../components/InputDropdown';
import Button from '../../components/Button';
import useStyles from './style';

const Registry = ({ editMode, setEditMode }) => {
  const { t } = useTranslation('profile');
  const { submitContainer } = useStyles();
  const { loading, data } = useGetRegistry();
  const {
    useForm,
    registers: { getRequiredMinLength, getRequiredEmail },
  } = useReactHookForm();
  const [updateUserProfile] = useUpdateUserProfile();
  const {
    register,
    triggerValidation,
    errors,
    getValues,
  } = useForm();

  const handleSubmitClick = async () => {
    if (editMode) {
      if (await triggerValidation()) {
        updateUserProfile({ variables: getValues() });
        setEditMode(false);
      }
    } else {
      setEditMode(true);
    }
  };

  if (loading) return <LocalLoading />;

  const {
    name,
    surname,
    height,
    weight,
    birthdate,
    gender,
    email,
  } = data;

  const renderErrors = () => Object.keys(errors).map((fieldName) => (
    <Zoom in>
      <Alert key={`${fieldName}-error`} severity="error">
        {errors[fieldName].message}
      </Alert>
    </Zoom>
  ));

  return (
    <Grid container spacing={3} justify="space-between">
      <Grid item xs={12} sm={6}>
        <Input
          readOnly={!editMode}
          square
          label={t('name')}
          name="name"
          value={name}
          required
          ref={register(getRequiredMinLength(t('name')))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          readOnly={!editMode}
          square
          label={t('surname')}
          name="surname"
          value={surname}
          required
          ref={register(getRequiredMinLength(t('surname')))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Input
          readOnly={!editMode}
          square
          label={t('height')}
          type="number"
          endAdornment="cm"
          value={height}
          name="height"
          ref={register()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          readOnly={!editMode}
          square
          label={t('birthdate')}
          type="date"
          value={birthdate}
          name="birthdate"
          ref={register()}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <InputDropdown
          readOnly={!editMode}
          square
          label={t('gender')}
          value={gender}
          name="gender"
          ref={register()}
          dropdownProps={{
            items: [
              { label: t('male'), value: 'MALE' },
              { label: t('female'), value: 'FEMALE' },
            ],
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Input
          readOnly={!editMode}
          square
          label={t('weight')}
          type="number"
          endAdornment="kg"
          value={weight}
          name="weight"
          ref={register()}
        />
      </Grid>

      <Grid item xs={12} container>
        <Grid item xs={12} sm={6}>
          <Input
            readOnly={!editMode}
            square
            required
            label={t('email')}
            type="email"
            value={email}
            name="email"
            ref={register(getRequiredEmail(t('email')))}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        {renderErrors()}
      </Grid>

      <Grid item xs={6}>
        <Grid
          container
          justify="flex-end"
          alignItems="flex-end"
          className={submitContainer}
        >
          <Zoom in={editMode}>
            <Button
              filled
              onClick={handleSubmitClick}
            >
              {t('common:save')}
            </Button>
          </Zoom>
        </Grid>
      </Grid>
    </Grid>
  );
};

Registry.propTypes = {
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
};

Registry.defaultProps = {
  editMode: true,
  setEditMode: () => {},
};

export default Registry;
