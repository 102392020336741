import AboutUs from './pages/AboutUs';
import Checkout from './pages/Checkout';
import Dashboard from './pages/Dashboard';
import Dish from './pages/Dish';
import Home from './pages/Home';
import HowItWorks from './pages/HowItWorks';
import Login from './pages/Authentication/Login';
import NoMatch from './pages/NoMatch';
import Notifications from './pages/Notifications';
import OrderDishes from './pages/OrderDishes';
import OrderDetails from './pages/OrderDetails';
import OrderHistory from './pages/OrderHistory';
import PasswordRecovery from './pages/Authentication/PasswordRecovery';
import PasswordReset from './pages/Authentication/PasswordReset';
import Recipe from './pages/Recipe';
import Registration from './pages/Authentication/Registration';
import ShoppingLists from './pages/ShoppingLists';
import UserProfile from './pages/UserProfile';

const routes = [{

  /**
   * Public routes - authenticated: false|undefined
   */
  path: '/',
  title: 'home',
  main: Home,
  exact: true,
  whiteBackgroundHeader: true,
}, {
  path: '/about-us',
  title: 'about-us',
  main: AboutUs,
  whiteBackgroundHeader: true,
}, {
  path: '/how-it-works',
  title: 'how-it-works',
  main: HowItWorks,
  whiteBackgroundHeader: true,
}, {
  path: '/dishes',
  title: 'dishes',
  main: OrderDishes,
  exact: true,
  showBottomCart: true,
}, {
  path: '/recipes',
  title: 'recipes',
  main: OrderDishes,
  exact: true,
}, {
  path: '/dishes/:dishId',
  main: Dish,
  showBottomCart: true,
}, {
  path: '/recipes/:recipeId',
  main: Recipe,
}, {
  path: '/checkout/:response?',
  main: Checkout,
}, {
  path: '/order-details/:response?',
  main: OrderDetails,
}, {
  path: '/notifications',
  main: Notifications,
},

/**
 * Private routes - authenticated: true
 */
{
  path: '/dashboard/:sectionSlug?',
  title: 'dashboard',
  main: Dashboard,
  authenticated: true,
}, {
  path: '/profile',
  title: 'user-profile',
  main: UserProfile,
  authenticated: true,
}, {
  path: '/orders',
  title: 'order-history',
  main: OrderHistory,
  authenticated: true,
}, {
  path: '/shopping-lists',
  title: 'shopping-lists',
  main: ShoppingLists,
  authenticated: true,
},

/**
 * Routes without header + footer
 */
{
  path: '/signin',
  title: 'signin',
  main: Login,
  outOfLayoutRoutes: true,
}, {
  path: '/signup',
  title: 'signup',
  main: Registration,
  outOfLayoutRoutes: true,
}, {
  path: '/password-recovery',
  title: 'password-recovery',
  main: PasswordRecovery,
  outOfLayoutRoutes: true,
}, {
  path: '/password-reset',
  title: 'password-reset',
  main: PasswordReset,
  outOfLayoutRoutes: true,
},

/**
 * Handling errors
 */
{
  title: '404',
  main: NoMatch,
}];

export default routes;
