import { useMutation } from '@apollo/react-hooks';
import { signinMutation } from '../../mutations/user';

export default () => {
  const [signin, {
    loading,
    data: { login } = {},
    error,
    client,
  }] = useMutation(signinMutation);

  const { user, ...authentication } = login || {};

  if (login) {
    client.resetStore();
  }

  return [signin, {
    loading,
    data: { user, authentication },
    errors: error && error.graphQLErrors,
  }];
};
