import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '../../../components/Dialog';
import useResponsive from '../../../hooks/useResponsive';
import PlanCard from './PlanCard';
import useCommonStyles from '../style';
import useStyles from './style';
import PremiumPlanForum from './PremiumPlanForum';

const Plans = () => {
  const { t } = useTranslation('plans');
  const [modalOpen, setModalOpen] = useState(false);
  const { innerContainer } = useCommonStyles();
  const { title: titleStyle, modalContainer } = useStyles();
  const { isSmallView } = useResponsive();

  const openModal = () => { setModalOpen(true); };
  const closeModal = () => { setModalOpen(false); };

  const plansData = t('plans', { returnObjects: true });

  const plansOptions = [{
    type: 'base',
    comingSoon: true,
  }, {
    type: 'pro',
    comingSoon: true,
  }, {
    type: 'premium',
    onClick: openModal,
  }];

  return (
    <>
      <Container className={innerContainer}>
        <Typography
          align="center"
          variant="h2"
          className={titleStyle}
        >
          {t('title')}
        </Typography>

        <Grid container justify="space-between" spacing={isSmallView ? 4 : 9}>
          {plansData.map(({
            type,
            title,
            description,
            features,
          }) => {
            const { comingSoon, onClick } = plansOptions.find((option) => option.type === type);

            return (
              <Grid item xs={12} md={4}>
                <PlanCard
                  title={title}
                  description={description}
                  features={features}
                  comingSoon={comingSoon}
                  onClick={onClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>

      {modalOpen && (
        <Dialog
          open
          large
          onClose={closeModal}
          className={modalContainer}
        >
          <PremiumPlanForum />
        </Dialog>
      )}
    </>
  );
};

export default Plans;
