import React, { useRef } from 'react';
import { styled } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  Switch,
  Route,
  useHistory,
  Redirect,
} from 'react-router-dom';
import librawayApolloClient from './libraries/apollo';
import tiledeskInit from './libraries/tiledesk';
import routes from './routes';
import { useAppContext } from './contexts/AppContext';
import { CartProvider } from './contexts/CartContext';
import { NotificationProvider } from './contexts/NotificationContext';
import WebNotification from './components/WebNotification';
import VersionBanner from './components/VersionBanner';
// import WelcomeModal from './components/WelcomeModal';
import Page from './components/Page';
import Header from './layout/Header';

const FullContainer = styled('div')({ width: '100%' });

const App = () => {
  const { authentication } = useAppContext();
  const history = useHistory();
  const { accessToken, tiledeskJwt } = authentication[0];
  const flag = useRef();

  window.tileDeskAsyncInit = tiledeskInit({
    accessToken,
    flag,
    history,
    tiledeskJwt,
  });

  /* eslint-disable react/prop-types */
  const mapRoutes = ({
    path,
    title,
    exact,
    main,
    authenticated,
    showBottomCart,
    whiteBackgroundHeader,
    contrastedHeader,
    outOfLayoutRoutes,
  }) => (
    <Route
      key={`${path}-${title}`}
      path={path}
      exact={exact}
      render={({ location }) => (
        authenticated && !accessToken ? (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        ) : (
          <>
            {!outOfLayoutRoutes && (
              <Header
                contrasted={contrastedHeader}
                whiteBackground={whiteBackgroundHeader}
              />
            )}
            <Page
              component={main}
              title={title}
              showBottomCart={showBottomCart}
            />
          </>
        )
      )}
    />
  );
  /* eslint-enable react/prop-types */

  return (
    <ApolloProvider client={librawayApolloClient}>
      <NotificationProvider>
        <CartProvider>
          <WebNotification />
          <FullContainer>
            {/* <WelcomeModal /> */}
            <VersionBanner />
            <Switch>
              {routes.map(mapRoutes)}
            </Switch>
          </FullContainer>
        </CartProvider>
      </NotificationProvider>
    </ApolloProvider>
  );
};

export default App;
