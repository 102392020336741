import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import { useAppContext } from '../../contexts/AppContext';
import { DataContext } from './contexts';
import useRecipesQuery from '../../graphql/hooks/recipes/useGetRecipesQuery';
import useDishesQuery from '../../graphql/hooks/dishes/useGetDishesQuery';
import BodyContainer from '../../components/BodyContainer';
import Head from './Head';
import TopFilters from './TopFilters';
import CommunityPreferred from './CommunityPreferred';
import Body from './Body';

const OrderDishes = () => {
  const { breakpoints } = useTheme();
  const isMobileView = useMediaQuery(breakpoints.down('sm'));
  const location = useLocation();
  const { pathname } = location;
  const dataType = pathname.split('/')[1];
  const { orderDishesFilters: [filters] } = useAppContext();
  const { getRecipes, ...recipesData } = useRecipesQuery({ filters, lazy: true });
  const { getDishes, ...dishesData } = useDishesQuery({ filters, lazy: true });
  const allData = {
    recipes: { executer: getRecipes, data: recipesData },
    dishes: { executer: getDishes, data: dishesData },
  };

  useEffect(allData[dataType].executer, [dataType]);

  return (
    <DataContext.Provider value={{ dataType, data: allData[dataType].data }}>
      <BodyContainer>
        <Container>
          {!isMobileView ? <Head /> : null}
          <TopFilters />
          {dataType === 'recipes' ? <CommunityPreferred /> : null}
          <Body />
        </Container>
      </BodyContainer>
    </DataContext.Provider>
  );
};

export default OrderDishes;
