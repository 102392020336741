import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette: { primary, white, black }, spacing, breakpoints }) => ({
  actionStyle: {
    fontSize: '1rem',
    padding: spacing(1),
    borderRadius: 10,
    '& svg': {
      opacity: ({ showDelete }) => (showDelete ? 1 : 0.3),
      transition: '150ms ease',
    },
  },

  tabContainer: {
    backgroundColor: primary.light,
    padding: spacing(4),
    [breakpoints.down('xs')]: {
      padding: spacing(2),
    },
  },

  tabsButtonRow: {
    position: 'relative',
    display: 'flex',
    overflowX: 'auto',
  },

  itemsContainer: {
    backgroundColor: white.main,
    padding: spacing(4),
    borderRadius: 20,
  },

  itemContaiener: {
    maxHeight: 40,
    [breakpoints.down('xs')]: {
      maxHeight: 80,
    },
  },

  dividerStyle: {
    margin: spacing(2, 0),
  },

  iconContainer: {
    minWidth: `calc(50px + ${spacing(2)}px)`,
  },

  ingredientIcon: {
    width: 50,
    marginRight: spacing(2),
  },

  ingredientPrimaryText: {
    lineHeight: 1.2,
    fontWeight: 900,
  },

  timesIconStyle: {
    '& .main': {
      stroke: black.dark,
    },
  },
}));

export default useStyles;
