import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useManageFavourite from '../../graphql/hooks/generic/useManageFavourite';
import { useAppContext } from '../../contexts/AppContext';
import Button from '../../components/Button';
import { ReactComponent as HearthIcon } from '../../assets/icons/hearth.svg';
import useStyles from './style';

const FavouriteButton = ({ type, reference, isFavourite }) => {
  const { t } = useTranslation('recipe');
  const { icon } = useStyles({ isFavourite });
  const { authentication: [authentication] } = useAppContext();
  const authenticated = !!authentication.accessToken;
  const [manageFavourite] = useManageFavourite();

  const handleClick = () => {
    manageFavourite({
      variables: {
        action: isFavourite ? 'REMOVE' : 'ADD',
        type,
        ref: reference,
      },
    });
  };

  if (!authenticated) return null;

  return (
    <Button
      fat
      stretched
      onClick={handleClick}
    >
      <HearthIcon className={icon} />
      {t(`${isFavourite ? 'remove' : 'add'}-to-favourites`)}
    </Button>
  );
};

FavouriteButton.propTypes = {
  type: PropTypes.oneOf(['MENUS', 'RECIPES', 'RESTAURANTS']).isRequired,
  reference: PropTypes.string.isRequired,
  isFavourite: PropTypes.bool,
};

FavouriteButton.defaultProps = {
  isFavourite: false,
};

export default FavouriteButton;
