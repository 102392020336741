import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import random from 'lodash/random';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useForgotPassword from '../../graphql/hooks/user/useForgotPassword';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import { useAppContext } from '../../contexts/AppContext';
import { ReactComponent as LibrawayLogo } from '../../assets/librawayLogoExtendedVertical.svg';
import useStyles from './style';

const forgotPasswordStatus = {
  EMAIL_NOT_SENT: 'error',
  EMAIL_SENT: 'success',
};

const Login = () => {
  const { t } = useTranslation('authentication');
  const history = useHistory();
  const [email, setEmail] = useState('');
  const { authentication: [authenticationState] } = useAppContext();
  const [forgotPassword, { loading, data: { status, message } }] = useForgotPassword();
  const { container, formContainer, title } = useStyles();

  useEffect(() => {
    if (authenticationState.accessToken) {
      history.push('/');
    }

    if (status === 'EMAIL_SENT') {
      setEmail('');
    }
  }, [
    history,
    authenticationState,
    authenticationState.accessToken,
    status,
  ]);

  const handleField = (setter) => ({ target: { value } }) => setter(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    forgotPassword({ variables: { email } });
  };

  const handleBack = () => {
    history.push('/signin');
  };

  const renderLoading = (
    <Loading size="small">
      {`${t('common:loading')}...`}
    </Loading>
  );

  const renderErrors = () => (
    <Alert key={random(0, 99)} severity={forgotPasswordStatus[status]}>{message}</Alert>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={container}
    >
      <LibrawayLogo width="128" />
      <Grow in>
        <Paper
          elevation={3}
          className={formContainer}
        >
          <Typography className={title}>{t('password-recovery')}</Typography>
          {status && renderErrors()}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              color="secondary"
              type="email"
              label={t('email')}
              value={email}
              disabled={loading}
              onChange={handleField(setEmail)}
            />

            <Button
              filled
              stretched
              disabled={loading || !email}
              type="submit"
            >
              {loading
                ? renderLoading
                : t('common:submit')}
            </Button>
          </form>

          <Button
            onClick={handleBack}
            stretched
            disabled={loading}
          >
            {t('back-to-login')}
          </Button>
        </Paper>
      </Grow>
    </Box>
  );
};

export default Login;
