import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { childrenPropType } from '../../components/propTypes';

const QuickMenuInlineItem = ({
  item: {
    name,
    link,
    disabled,
    render: Render,
    selfHandleClick,
  },
}) => {
  const { t } = useTranslation('header');
  const history = useHistory();

  const handleItemClick = (action) => () => {
    history.push(`/${action}`);
  };

  return (
    <Tooltip title={t(name)} placement="top">
      {!selfHandleClick ? (
        <Button
          disabled={disabled}
          onClick={handleItemClick(link || name)}
          style={{ minWidth: 0 }}
        >
          {Render ? <Render /> : name}
        </Button>
      ) : <Render disabled={disabled} />}
    </Tooltip>
  );
};

QuickMenuInlineItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    disabled: PropTypes.bool,
    render: childrenPropType,
    selfHandleClick: PropTypes.bool,
  }).isRequired,
};

export default QuickMenuInlineItem;
