import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import smartphoneImage from '../../assets/smartphone_libraway_web_opacity.png';
import Button from '../../components/Button';
import comingSoonIcon from '../../assets/icons/nastro_presto-online.svg';
import useStyles from './style';

const Presentation = () => {
  const { t } = useTranslation('home');
  const history = useHistory();
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const {
    presentationContainer,
    presentationTitle,
    presentationText,
    buttonContainer,
    smartphoneContainer,
    smartphoneStyle,
    background,
  } = useStyles();

  return (
    <div className={background} style={{ minHeight: 570 }}>
      <Container className={presentationContainer}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography
              gutterBottom
              variant="h1"
              className={presentationTitle}
            >
              {t('eat-healthy')}
            </Typography>
            <Typography variant="body2" className={presentationText}>
              <span
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: t('main-text') }}
              />
            </Typography>
            <Grid
              container
              className={buttonContainer}
              justify={isSmallView ? 'center' : 'flex-start'}
              spacing={2}
            >
              <Grid item xs={8} sm="auto">
                <Button
                  filled
                  stretched={isSmallView}
                  fitted={!isSmallView}
                  onClick={() => { history.push('dishes'); }}
                >
                  {t('button-order-dishes')}
                </Button>
              </Grid>
              <Grid item xs={8} sm="auto">
                <Button
                  stretched={isSmallView}
                  fitted={!isSmallView}
                  onClick={() => { history.push('how-it-works'); }}
                >
                  {t('button-active-plan')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            md={6}
            justify="center"
            alignItems="center"
            className={smartphoneContainer}
          >
            <img
              src={comingSoonIcon}
              alt="Badge"
              style={{
                position: 'absolute',
                bottom: isSmallView ? 74 : 364,
                right: '10%',
                zIndex: 10,
              }}
            />
            <img
              src={smartphoneImage}
              alt="Smartphone asset"
              className={smartphoneStyle}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Presentation;
