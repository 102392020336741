import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useAppContext } from '../../contexts/AppContext';
import Button from '../../components/Button';
import UserDropdown from './UserDropdown';
import { ReactComponent as CartIcon } from '../../assets/icons/carrello.svg';
import { userItems } from './items';
import useStyles from './style';

const TopRightMenu = () => {
  const { t } = useTranslation('header');
  const history = useHistory();
  const { topRightContainer, signinStyle, iconButton } = useStyles();
  const { authentication: [authentication] } = useAppContext();
  const isAuthenticated = !!authentication.accessToken;

  const handleSigninClick = () => {
    history.push('/signin');
  };

  return (
    <Grid
      container
      justify="flex-end"
      alignItems="center"
      className={topRightContainer}
    >
      <Grid item>
        <Grid container alignItems="center">
          {!isAuthenticated ? (
            <Button
              filled
              fitted
              style={{ padding: '10px 20px', fontSize: 15 }}
              onClick={() => { history.push('/how-it-works'); }}
            >
              {t('active-plan')}
            </Button>
          ) : (
            <UserDropdown menuItems={userItems} />
          )}
        </Grid>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          <ButtonBase
            disableRipple
            className={iconButton}
            onClick={() => { history.push('/checkout'); }}
          >
            <CartIcon width={20} height={30} />
          </ButtonBase>
        </Grid>
      </Grid>

      {!isAuthenticated && (
        <Grid item>
          <ButtonBase
            disableRipple
            onClick={handleSigninClick}
          >
            <Typography className={signinStyle}>
              {t('signin')}
            </Typography>
          </ButtonBase>
        </Grid>
      )}
    </Grid>
  );
};

export default TopRightMenu;
