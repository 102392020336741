import gql from 'graphql-tag';

const manageFavouriteMutation = gql`
  mutation ManageFavorite(
    $action: FavoriteAction!,
    $type: ObjectType!,
    $ref: ID!,
  ) {
    manageFavorite(
      action: $action,
      type: $type,
      ref: $ref,
    ) {
      id
    }
  }
`;

const manageVoteMutation = gql`
  mutation ManageVote(
    $value: Int,
    $type: ObjectType!,
    $ref: ID!,
  ) {
    manageVote(
      value: $value,
      type: $type,
      ref: $ref,
    ) {
      id
    }
  }
`;

const requestPremiumPlanMutation = gql`
  mutation RequestPremiumPlan(
    $firstName: String,
    $lastName: String,
    $email: String!,
    $phone: String,
    $privacyCheck: Boolean!,
    $marketingCheck: Boolean,
  ) {
    requestPremiumPlan(
      first_name: $firstName,
      last_name: $lastName,
      email: $email,
      phone: $phone,
      privacy_check: $privacyCheck,
      marketing_check: $marketingCheck,
    )
  }
`;

const requestSubscribeMutation = gql`
  mutation RequestSubscribe(
    $firstName: String,
    $lastName: String,
    $email: String!,
    $privacyCheck: Boolean!,
    $marketingCheck: Boolean,
  ) {
    requestSubscribe(
      first_name: $firstName,
      last_name: $lastName,
      email: $email,,
      privacy_check: $privacyCheck,
      marketing_check: $marketingCheck,
    )
  }
`;

export {
  manageFavouriteMutation,
  manageVoteMutation,
  requestPremiumPlanMutation,
  requestSubscribeMutation,
};
