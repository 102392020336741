import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

const PrivacyCheckbox = ({ label, checked, onChange }) => (
  <Grid container justify="flex-start">
    <Grid item container xs={1} justify="center">
      <Checkbox checked={checked} onChange={onChange} />
    </Grid>
    <Grid item xs={11}>
      <Typography variant="caption">
        <span
            // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: label }}
        />
      </Typography>
    </Grid>
  </Grid>
);

PrivacyCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

PrivacyCheckbox.defaultProps = {
  label: '',
  checked: false,
  onChange: () => {},
};

export default PrivacyCheckbox;
