import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const socials = {
  facebook: {
    icon: faFacebookF,
    url: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
  },
  instagram: {
    icon: faInstagram,
    url: '',
  },
  twitter: {
    icon: faTwitter,
    url: '',
  },
};

const SocialAvatar = ({
  disabled,
  size,
  social,
  children,
  url,
}) => {
  const iconSizes = {
    small: { icon: 'sm', style: 32 },
    medium: { icon: '1x', style: 40 },
    large: { icon: 'lg', style: 48 },
  };

  const { avatar, button } = useStyles({
    size: iconSizes[size].style,
    disabled,
  });

  return (
    <ButtonBase
      href={url || socials[social].url}
      target="_blank"
      className={button}
      onClick={() => {}}
      disabled={disabled}
    >
      <Avatar className={avatar}>
        {social ? (
          <FontAwesomeIcon
            icon={socials[social].icon}
            size={iconSizes[size].icon}
          />
        ) : children}
      </Avatar>
    </ButtonBase>
  );
};

SocialAvatar.propTypes = {
  children: childrenPropType,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  social: PropTypes.oneOf(['facebook', 'instagram', 'twitter']),
  url: PropTypes.string,
};

SocialAvatar.defaultProps = {
  children: '',
  disabled: false,
  size: 'medium',
  social: '',
  url: '',
};

export default SocialAvatar;
