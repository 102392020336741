import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const mergeClassNames = (...classNames) => classNames.reduce((accumulator, object) => {
  const result = accumulator;

  Object.keys(object).forEach((key) => {
    result[key] = result[key] ? `${result[key]} ${object[key]}` : object[key];
  });

  return result;
}, {});

const MobileDrawer = ({
  open,
  onClose,
  onScroll,
  classes,
  children,
}) => (
  <Drawer
    open={open}
    anchor="right"
    onClose={onClose}
    classes={mergeClassNames(classes, useStyles())}
    onScroll={onScroll}
  >
    {children}
  </Drawer>
);

MobileDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onScroll: PropTypes.func,
  classes: PropTypes.shape(),
  children: childrenPropType.isRequired,
};

MobileDrawer.defaultProps = {
  open: false,
  onClose: () => {},
  onScroll: () => {},
  classes: {},
};

export default MobileDrawer;
