import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import random from 'lodash/random';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import useLocationQuery from '../../hooks/useLocationQuery';
import useUpdateForgottenPassword from '../../graphql/hooks/user/useUpdateForgottenPassword';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import { useAppContext } from '../../contexts/AppContext';
import { ReactComponent as LibrawayLogo } from '../../assets/librawayLogoExtendedVertical.svg';
import useStyles from './style';

const forgotPasswordStatus = {
  EMAIL_NOT_SENT: 'error',
  EMAIL_SENT: 'success',
};

const Login = () => {
  const { t } = useTranslation('authentication');
  const history = useHistory();
  const query = useLocationQuery();
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const { authentication: [authenticationState] } = useAppContext();
  const [forgotPassword, { loading, data: { status, message } }] = useUpdateForgottenPassword();
  const { container, formContainer, title } = useStyles();

  useEffect(() => {
    if (authenticationState.accessToken) {
      history.push('/');
    }
  }, [
    history,
    authenticationState,
    authenticationState.accessToken,
    status,
  ]);


  const handleField = (setter) => ({ target: { value } }) => setter(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    forgotPassword({
      variables: {
        email: query.get('email'),
        token: query.get('token'),
        password,
        passwordConfirmation: passwordRepeat,
      },
    });
  };

  const handleBack = () => {
    history.push('/signin');
  };

  const renderLoading = (
    <Loading size="small">
      {`${t('common:loading')}...`}
    </Loading>
  );

  const renderErrors = () => (
    <Alert key={random(0, 99)} severity={forgotPasswordStatus[status]}>{message}</Alert>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={container}
    >
      <LibrawayLogo width="128" />
      <Grow in>
        <Paper
          elevation={3}
          className={formContainer}
        >
          <Typography className={title}>{t('password-recovery')}</Typography>
          {status && renderErrors()}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              color="secondary"
              type="password"
              label={t('password')}
              value={password}
              disabled={loading}
              onChange={handleField(setPassword)}
            />

            <TextField
              fullWidth
              color="secondary"
              type="password"
              label={t('repeat-password')}
              value={passwordRepeat}
              disabled={loading}
              onChange={handleField(setPasswordRepeat)}
            />

            <Button
              filled
              stretched
              disabled={
                loading
                || !password
                || !passwordRepeat
                || password !== passwordRepeat
              }
              type="submit"
            >
              {loading
                ? renderLoading
                : t('common:submit')}
            </Button>
          </form>

          <Button
            onClick={handleBack}
            stretched
            disabled={loading}
          >
            {t('back-to-login')}
          </Button>
        </Paper>
      </Grow>
    </Box>
  );
};

export default Login;
