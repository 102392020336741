import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import useGetRelatedRecipesByRecipeQuery from '../../graphql/hooks/recipes/useGetRelatedRecipesByRecipeQuery';
import LocalLoading from '../../components/LocalLoading';
import Card from '../../components/Card';
import useStyles from './style';

const RecommendedRecipes = ({ triggerQuery, recipeId }) => {
  const {
    getRelatedRecipesByRecipe,
    loading,
    data: recipes,
  } = useGetRelatedRecipesByRecipeQuery({ recipesLimit: 4, recipeId });
  const { mobileCarousel, descriptionBox } = useStyles();
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const history = useHistory();

  useEffect(() => {
    if (triggerQuery) {
      getRelatedRecipesByRecipe();
    }
  }, [getRelatedRecipesByRecipe, triggerQuery]);

  const handleClick = (id) => () => { history.push(`/recipes/${id}`); };

  /* eslint-disable react/prop-types */
  const renderCard = ({
    node: {
      title,
      image,
      commentsCount,
      avgVote,
      foods,
      id,
    },
  }) => (
    <Card
      key={id}
      title={title}
      type="recipes"
      image={image}
      foods={foods}
      commentsCount={commentsCount}
      valuation={avgVote}
      handleClick={handleClick(id)}
    />
  );
  /* eslint-enable react/prop-types */

  /* eslint-disable react/prop-types */
  const renderGridCard = ({
    node: {
      id,
      title,
      image,
      foods,
      commentsCount,
      valuation,
    },
  }) => (
    <Grid
      key={id}
      md
      item
      container
      justify="center"
    >
      <Card
        title={title}
        type="recipes"
        image={image}
        foods={foods}
        commentsCount={commentsCount}
        valuation={valuation}
        handleClick={handleClick(id)}
      />
    </Grid>
  );
  /* eslint-enable react/prop-types */

  if (loading) {
    return <LocalLoading />;
  }

  if (recipes && recipes.length) {
    if (isSmallView) {
      return (
        <Grid container>
          <div className={mobileCarousel}>
            {recipes.slice(0, 4).map(renderCard)}
          </div>
        </Grid>
      );
    }

    return (
      <Box className={descriptionBox}>
        <Grid container justify="space-between">
          {recipes.slice(0, 4).map(renderGridCard)}
        </Grid>
      </Box>
    );
  }

  return null;
};

RecommendedRecipes.propTypes = {
  triggerQuery: PropTypes.bool,
  recipeId: PropTypes.string,
};

RecommendedRecipes.defaultProps = {
  triggerQuery: false,
  recipeId: null,
};

export default RecommendedRecipes;
