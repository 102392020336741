import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import { childrenPropType } from './propTypes';

const useStyles = makeStyles(({ palette: { common, secondary } }) => ({
  link: {
    textDecoration: 'none',
    fontWeight: 700,
    color: ({ color }) => {
      if (color === 'black') return common.black;
      return secondary.main;
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const Link = forwardRef(({
  children,
  className,
  onClick,
  to,
  styles,
  underline,
  color,
}, ref) => (
  <RouterLink
    className={className || useStyles({ color }).link}
    ref={ref}
    onClick={onClick}
    to={to}
  >
    <MuiLink
      style={styles}
      underline={underline === true ? 'always' : underline}
    >
      {children}
    </MuiLink>
  </RouterLink>
));

Link.propTypes = {
  children: childrenPropType.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  styles: PropTypes.shape(),
  underline: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['none', 'always', 'hover']),
  ]),
  color: PropTypes.oneOf(['black', 'secondary']),
};

Link.defaultProps = {
  className: '',
  onClick: () => {},
  styles: {},
  underline: 'none',
  color: 'secondary',
};

export default Link;
