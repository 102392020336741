import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import NativeSelect from '@material-ui/core/NativeSelect';
import { uselessFunction } from '../../libraries/helpers';
import { childrenPropType } from '../propTypes';
import useStyles from './style';

const Select = ({
  children,
  label,
  onChange,
  small,
  value,
}) => {
  const { labelStyle, root } = useStyles({ small });

  return (
    <Grid container direction="column">
      {label && <span className={labelStyle}>{label}</span>}
      <NativeSelect
        value={value}
        onChange={onChange}
        className={root}
        disableUnderline
      >
        {children}
      </NativeSelect>
    </Grid>
  );
};

Select.propTypes = {
  children: childrenPropType,
  label: PropTypes.string,
  onChange: PropTypes.func,
  small: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Select.defaultProps = {
  children: null,
  label: '',
  onChange: uselessFunction,
  small: false,
  value: null,
};

export default Select;
