import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useCartContext } from '../../contexts/CartContext';
import { useDishContext } from '../../contexts/DishContext';
import {
  capitalize as capitalizeFunc,
  formatMoney,
  getImagesSet,
  setDocumentTitle,
} from '../../libraries/helpers';
import LocalLoading from '../../components/LocalLoading';
import SizesDialog from '../../components/SizesDialog';
import Button from '../../components/Button';
import TabContainer from './TabContainer';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import useStyles from './style';
import BackButton from '../../components/BackButton';

const defaultIngredientIcon = 'https://media.libraway.com/135/5e8736796bcdd_Pasta.svg';

const Main = () => {
  const { t } = useTranslation(['dish', 'ingredients']);
  const {
    detailContainer,
    imageContainer,
    ingredientIcon,
    ingredientLabel,
    oldPrice,
  } = useStyles();
  const {
    loading,
    data: {
      id,
      title,
      notes,
      image,
      foods,
      price,
      discountedPrice,
      sizes,
      published,
    },
  } = useDishContext();

  if (title) {
    setDocumentTitle(capitalizeFunc(title));
  }

  const [{ voidSpacesLeft }] = useCartContext();
  const { useClasses, breakpoints, spacing } = useTheme();
  const { capitalize, lineThrough } = useClasses();
  const isMobileView = useMediaQuery(breakpoints.down('md'));
  const [modalOpen, setModalOpen] = useState(false);

  if (loading) return <LocalLoading />;

  const defaultSize = sizes.find(({ additionalCost }) => additionalCost === 0);

  const getPrice = () => discountedPrice || price;

  const handleOpen = () => { setModalOpen(true); };
  const handleClose = () => { setModalOpen(false); };

  const renderIngredient = ({ name, image: ingredientImage }, index) => {
    const toRender = [
      <Grid
        key={name}
        item
        xs={2}
      >
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <img
              alt={name}
              src={ingredientImage ? ingredientImage.url : defaultIngredientIcon}
              className={ingredientIcon}
              style={{ pointerEvents: 'none' }}
            />
          </Grid>
          <Grid item container align="flex-start" justify="center">
            <span className={ingredientLabel}>{name}</span>
          </Grid>
        </Grid>
      </Grid>,
    ];

    if (foods[index + 1]) {
      toRender.push(
        <Grid
          item
          key={`plus-${index}`}
          style={{ display: 'flex' }}
        >
          <Grid container justify="center" alignItems="center">
            <PlusIcon width="20px" height="20px" />
          </Grid>
        </Grid>,
      );
    }

    return toRender;
  };

  return (
    <>
      <Grid container spacing={isMobileView ? 4 : 8}>

        {/* Head */}
        <Grid container item>

          <Grid item container spacing={4}>
            <Grid item xs={12}>
              <BackButton>{t('back-to-dish')}</BackButton>
            </Grid>

            {isMobileView ? (
              <Grid item xs={12}>
                <Typography variant="h2" className={capitalize}>
                  {title}
                </Typography>
              </Grid>
            ) : null}

            <Grid item xs={12} md={6}>
              <div className={imageContainer}>
                {image && image.length ? (
                  <img
                    alt={title}
                    src={image[0].default}
                    srcSet={getImagesSet(image[0], 'default')}
                    style={{ pointerEvents: 'none' }}
                  />
                ) : null}
              </div>
            </Grid>

            <Grid item xs={12} md={6} className={detailContainer}>
              <Grid
                container
                direction="column"
                spacing={2}
                justify="space-between"
              >
                {!isMobileView ? (
                  <Grid item>
                    <Typography variant="h2" className={capitalize}>
                      {title}
                    </Typography>
                  </Grid>
                ) : null}

                {foods && foods.length ? (
                  <Grid item container spacing={2}>
                    {foods.map(renderIngredient)}
                  </Grid>
                ) : null}

                <Grid item>
                  <Grid container direction="column" spacing={0}>
                    <Grid item>
                      {`${t(defaultSize.dimension.toLowerCase())} | ${defaultSize.grams}${t('ingredients:gram')}`}
                    </Grid>
                    <Grid item>
                      {!!discountedPrice && (
                        <Typography
                          variant="h3"
                          className={classNames(capitalize, lineThrough, oldPrice)}
                        >
                          {formatMoney(price)}
                        </Typography>
                      )}
                      <Typography variant="h2" className={capitalize}>
                        {formatMoney(getPrice())}
                      </Typography>
                    </Grid>

                    <Grid item style={{ marginTop: spacing(2) }}>
                      <Button
                        fitted
                        disabled={!voidSpacesLeft || !published}
                        onClick={handleOpen}
                      >
                        {t('add-to-cart')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {notes ? (
                  <Grid item>
                    <Typography variant="caption">
                      <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: notes }}
                      />
                    </Typography>
                  </Grid>
                ) : null}

              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Procedures */}
        <Grid container item>
          <TabContainer />
        </Grid>

      </Grid>
      {sizes.length > 0 && modalOpen && (
        <SizesDialog
          cartInfo={{
            id,
            title,
            image,
            price: getPrice(),
          }}
          open
          onClose={handleClose}
          sizes={sizes}
        />
      )}
    </>
  );
};

export default Main;
