import { makeStyles } from '@material-ui/core/styles';
import { headerHeight, headerMobileHeight } from '../../libraries/helpers';

const useStyles = makeStyles(({
  spacing,
  breakpoints,
  palette: { primary, secondary, common },
}) => ({
  appbar: {
    [breakpoints.down('md')]: { height: spacing(headerMobileHeight) },
    [breakpoints.up('md')]: { height: spacing(headerHeight) },
    backgroundColor: ({ shrink = false, whiteBackground }) => ((shrink || whiteBackground) ? common.white : 'transparent'),
    boxShadow: ({ shrink }) => (shrink ? '0px 0px 6px 3px rgba(0,0,0,0.1)' : 'none'),

    transition: `
      height 150ms ease,
      box-shadow 50ms ease
    `,
  },

  burgerMenu: {
    color: primary.dark,
    minWidth: 0,
  },

  container: {
    height: '100%',
    [breakpoints.up('md')]: {
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
    [breakpoints.down('md')]: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },

  logoContainer: {
    marginRight: spacing(3),
    lineHeight: 0,
    [breakpoints.down('md')]: { flexGrow: 1 },
    [breakpoints.up('md')]: { flexGrow: 'unset' },
    [breakpoints.up('xs')]: { marginRight: spacing(1) },
  },

  logo: {
    [breakpoints.up('md')]: { height: 80 },
    [breakpoints.down('md')]: { height: 60 },
    [breakpoints.down('xs')]: { height: 50 },
    transition: 'height 500ms ease 90ms',
  },

  cartImageContainer: {
    '& .cart-main': {
      fill: common.black,
    },
  },

  signinStyle: {
    fontSize: 15,
    fontWeight: 900,
    textTransform: 'uppercase',
    color: secondary.main,
  },

  iconButton: {
    '& .st0': {
      transition: 'fill 100ms ease',
    },
    '&:hover .st0': {
      fill: secondary.main,
    },
  },

  menuItemsContainer: {
    '& > * + *': {
      marginLeft: spacing(1),
    },
  },

  topRightContainer: {
    '& > * + *': {
      marginLeft: spacing(3),
    },
  },
}));

export default useStyles;
