import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setDocumentTitle } from '../../libraries/helpers';
import BottomCart from './BottomCart';
import Footer from '../../layout/Footer';

const Page = ({ component: Component, title, showBottomCart }) => {
  const { t } = useTranslation('titles');

  if (title) {
    setDocumentTitle(t(title));
  }

  return (
    <>
      <Component />
      <Footer />
      {showBottomCart ? <BottomCart /> : null}
    </>
  );
};

Page.propTypes = {
  component: PropTypes.func.isRequired,
  title: PropTypes.string,
  showBottomCart: PropTypes.bool,
};

Page.defaultProps = {
  title: undefined,
  showBottomCart: false,
};

export default Page;
