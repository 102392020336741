import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import librawayV2Theme from '../../theme/v2';
import BodyContainer from '../../components/BodyContainer';
import Presentation from './Presentation';
import HowItWorksPlans from './HowItWorksPlans';
import FoodMap from './FoodMap';
import Plans from './Plans';
// import InfoForm from './Plans/InfoForm';

const HowItWorks = () => (
  <ThemeProvider theme={librawayV2Theme}>
    <BodyContainer backgroundFixed style={{ paddingBottom: 0 }}>
      <Presentation />
      <HowItWorksPlans />
      <FoodMap />
      <Plans />
      {/* <InfoForm /> */}
    </BodyContainer>
  </ThemeProvider>
);

export default HowItWorks;
