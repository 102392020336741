import React, {
  useState,
  useEffect,
  forwardRef,
  memo,
} from 'react';
import PropTypes from 'prop-types';
import countBy from 'lodash/countBy';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Menu from '@material-ui/core/Menu';
import useGetNotifications from '../../graphql/hooks/notifications/useGetNotifications';
import useReadNotification from '../../graphql/hooks/notifications/useReadNotification';
import useOnNotificationCreated from '../../graphql/hooks/notifications/useOnNotificationsCreated';
import { librawayStorage } from '../../libraries/helpers';
import { useNotificationContext } from '../../contexts/NotificationContext';
import LocalLoading from '../LocalLoading';
import ReadAllButton from './ReadAllButton';
import NotifyItem from './NotifyItem';
import Placeholder from './Placeholder';
import { ReactComponent as Icon } from '../../assets/icons/notifications.svg';

const NotificationIcon = memo(forwardRef(({ disabled }, ref) => {
  const [anchorElement, setAnchorElement] = useState(null);
  const { loading, data, refetch } = useGetNotifications();
  const [readNotification] = useReadNotification();
  const response = useOnNotificationCreated();
  const {
    setBody,
    setIgnore,
    setOnClick,
    setTitle,
  } = useNotificationContext();
  const unreadCount = data ? countBy(data, ({ node: { readAt } }) => readAt === null).true : 0;

  const handleOpen = ({ currentTarget }) => { setAnchorElement(currentTarget); };
  const handleClose = () => { setAnchorElement(false); };

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [response, data]);

  useEffect(() => {
    if (data && data.length) {
      const {
        id,
        readAt,
        message,
        title,
      } = data[0].node;

      if (!readAt && librawayStorage.get('notified') !== id) {
        setIgnore(false);
        setOnClick(() => () => {
          window.tiledesk.open();
          window.focus();
          handleClose();
          readNotification({ variables: { id } });
        });
        setTitle(title);
        setBody(message);
        librawayStorage.set('notified', id);
      }
    }
  }, [data]);

  const renderNotifications = () => {
    if (data && data.length) {
      return [
        <ReadAllButton key="readAll" />,
        data.map(
          ({
            node: {
              id,
              title,
              message,
              readAt,
              createdAt,
            },
          }) => (
            <NotifyItem
              key={id}
              id={id}
              title={title}
              message={message}
              readAt={readAt}
              createdAt={createdAt}
              handleClose={handleClose}
            />
          ),
        ),
      ];
    }

    return <Placeholder />;
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={handleOpen}
        style={{ minWidth: 0 }}
        ref={ref}
      >
        <Badge
          color="primary"
          badgeContent={unreadCount}
          invisible={!unreadCount}
        >
          <Icon />
        </Badge>
      </Button>

      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        {loading ? <LocalLoading /> : renderNotifications()}
      </Menu>
    </>
  );
}), ({ disabled: prevDisabled }, { disabled: nextDisabled }) => prevDisabled === nextDisabled);

NotificationIcon.propTypes = {
  disabled: PropTypes.bool,
};

NotificationIcon.defaultProps = {
  disabled: false,
};

export default NotificationIcon;
