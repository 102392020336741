import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const useReactHookForm = () => {
  const { t } = useTranslation('validations');

  const getRequired = (fieldName) => ({
    required: true,
    value: true,
    message: t('field-required', { fieldName: t(fieldName) }),
  });

  const getMinLength = (fieldName, minLength = 3) => ({
    value: minLength,
    message: t('field-min', { fieldName: t(fieldName), minLength }),
  });

  const getRequiredMinLength = (fieldName, minLength) => ({
    required: getRequired(fieldName),
    minLength: getMinLength(fieldName, minLength),
  });

  const getEmail = (fieldName) => ({
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: t('field-email', { fieldName: t(fieldName) }),
    },
  });

  const getRequiredEmail = (fieldName) => ({
    required: getRequired(fieldName),
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: t('field-email', { fieldName: t(fieldName) }),
    },
  });

  const getMatch = (valueToMatch) => ({
    validate: (value) => value === valueToMatch || t('field-match'),
  });

  return {
    useForm,
    registers: {
      getEmail,
      getRequired,
      getRequiredEmail,
      getRequiredMinLength,
      getMatch,
      getMinLength,
    },
  };
};

export default useReactHookForm;
