import React from 'react';
import PropTypes from 'prop-types';
import MuiDivider from '@material-ui/core/Divider';
import useStyles from './style';

const marginPropTypes = [PropTypes.string, PropTypes.number];

const Divider = ({
  margin,
  marginTop,
  marginBottom,
  color,
}) => {
  const { root } = useStyles({
    margin,
    marginTop,
    marginBottom,
    color,
  });

  return <MuiDivider className={root} />;
};

Divider.propTypes = {
  margin: PropTypes.oneOfType(marginPropTypes),
  marginTop: PropTypes.oneOfType(marginPropTypes),
  marginBottom: PropTypes.oneOfType(marginPropTypes),
  color: PropTypes.oneOf(['primary', 'secondary', 'grey']),
};

Divider.defaultProps = {
  margin: null,
  marginTop: null,
  marginBottom: null,
  color: 'secondary',
};

export default Divider;
