import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { ThemeProvider, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import librawayV2Theme from '../../theme/v2';
import RecipeContext from './contexts';
import { useAppContext } from '../../contexts/AppContext';
import { getImagesSet } from '../../libraries/helpers';
import BackButton from '../../components/BackButton';
import SocialAvatar from '../../components/SocialAvatar';
import ImageBadge from './ImageBadge';
import Vote from './Vote';
import FavouriteButton from './FavouriteButton';
import Info from './Info';
import Ingredients from './Ingredients';
import Comment from './Comment';
import CommentsActions from './CommentsActions';
import RecommendedRecipes from './RecommendedRecipes';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';
import { ReactComponent as CommentsIcon } from '../../assets/icons/comments.svg';
import useStyles from './style';
import ValuationScale from '../../components/ValuationScale';

const Main = () => {
  const {
    descriptionBox,
    detailContainer,
    divider,
    icon,
    imageContainer,
    ingredientsSubtitle,
    subTitle,
    shareContainer,
  } = useStyles();
  const [recommendedHasBeenVisible, setRecommendedHasBeenVisible] = useState(false);
  const {
    id,
    title,
    description,
    image,
    comments: {
      pageInfo: { total: commentsCount },
      edges: comments = [],
    },
    avgVote,
    foods,
    isFavourite,
    hasVoted,
  } = useContext(RecipeContext);
  const { t } = useTranslation('recipe');
  const { useClasses, breakpoints } = useTheme();
  const { capitalize } = useClasses();
  const isMobileView = useMediaQuery(breakpoints.down('md'));
  const { REACT_APP_VALUATION_SCALE: valuationScale } = process.env;
  const { authentication: [authentication] } = useAppContext();
  const isAuthenticated = !!authentication.accessToken;

  const checkRecommendedIsVisible = (isVisible) => {
    if (isVisible && !recommendedHasBeenVisible) {
      setRecommendedHasBeenVisible(true);
    }
  };

  const renderTitleIngredients = () => `(${foods.map(({ name }) => name).join(' + ')})`;

  // eslint-disable-next-line react/prop-types
  const renderComment = ({ node: { id: commentId, text } }) => (
    <Comment key={commentId} id={commentId} text={text} />
  );

  return (
    <Grid container spacing={isMobileView ? 4 : 8}>

      {/* Head */}
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <BackButton goTo="/recipes">{t('back-to-recipe')}</BackButton>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2" className={capitalize}>
            {title}
            <span className={ingredientsSubtitle}>{renderTitleIngredients()}</span>
          </Typography>
        </Grid>

        <Grid item xs={12} className={subTitle}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Grid container alignItems="center">
                    <CommentsIcon className={icon} />
                    {t('comment', { count: commentsCount })}
                  </Grid>
                </Grid>
                <Grid item>
                  <span className={divider} />
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <StarIcon className={icon} />
                    <ValuationScale>{avgVote}</ValuationScale>
                  </Grid>
                </Grid>
                {isAuthenticated ? (
                  <>
                    <Grid item>
                      <span className={divider} />
                    </Grid>
                    <Grid item>
                      <Vote
                        valuationScale={valuationScale}
                        ownVote={hasVoted || undefined}
                        type="RECIPES"
                        reference={id}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container direction="column">
                <Grid item container justify="flex-end" className={shareContainer}>{t('common:share')}</Grid>
                <Grid item container justify="flex-end" className={shareContainer} spacing={1}>
                  <Grid item>
                    <SocialAvatar
                      size="small"
                      social="facebook"
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <SocialAvatar
                      size="small"
                      social="instagram"
                      disabled
                    />
                  </Grid>
                  <Grid item>
                    <SocialAvatar
                      size="small"
                      social="twitter"
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={4}>
          <Grid item xs={12} md={8}>
            <div className={imageContainer}>
              <ImageBadge />
              {image.length ? (
                <img
                  alt={title}
                  src={image[0].default}
                  srcSet={getImagesSet(image[0], 'default')}
                />
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={detailContainer}>
              <FavouriteButton
                type="RECIPES"
                reference={id}
                isFavourite={isFavourite}
              />
              <Info />
            </div>
          </Grid>
        </Grid>
      </Grid>

      {/* Ingredients */}
      <Grid container item>
        <Ingredients />
      </Grid>

      {/* Procedure */}
      <Grid container item>
        <Box className={descriptionBox}>
          <ThemeProvider theme={librawayV2Theme}>
            <Typography variant="h2" gutterBottom>{t('procedure')}</Typography>
            <Typography>
              <span
              // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Typography>
          </ThemeProvider>
        </Box>
      </Grid>

      {/* Comments */}
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>{`${t('comment-title')} (${commentsCount})`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item container spacing={4}>
            {comments.map(renderComment)}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CommentsActions />
        </Grid>
      </Grid>

      {/* Recommended recipes */}
      <VisibilitySensor
        partialVisibility
        offset={{ top: 300 }}
        onChange={checkRecommendedIsVisible}
      >
        <Grid container item direction="column">
          <Typography variant="h2" gutterBottom>{t('recommended-recipes')}</Typography>
          <RecommendedRecipes
            triggerQuery={recommendedHasBeenVisible}
            recipeId={id}
          />
        </Grid>
      </VisibilitySensor>
    </Grid>
  );
};

export default Main;
