import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useAppContext } from '../../contexts/AppContext';
import { DataContext } from './contexts';
import Pillow from '../../components/Pillow';
import TopFilterSearch from './TopFilterSearch';
import { ReactComponent as DishIcon } from '../../assets/icons/dish.svg';
import { ReactComponent as RecipeIcon } from '../../assets/icons/recipe.svg';
import { ReactComponent as RestaurantIcon } from '../../assets/icons/restaurant.svg';

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    paddingTop: spacing(6),
    paddingBottom: spacing(6),
    [breakpoints.down('md')]: {
      paddingTop: spacing(3),
      paddingBottom: spacing(3),
    },
    [breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },

  title: {
    [breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const pillows = [{
  name: 'dishes',
  icon: DishIcon,
}, {
  name: 'recipes',
  icon: RecipeIcon,
}, {
  name: 'restaurants',
  icon: RestaurantIcon,
  hidden: true,
}];

const TopFilters = () => {
  const { t } = useTranslation('orderDishes');
  const history = useHistory();
  const { orderDishesFilters: [filters, setFilters] } = useAppContext();
  const { dataType } = useContext(DataContext);
  const { container, title } = useStyles();

  const selectPillow = (pillow) => () => {
    history.push(`/${pillow}`);
    setFilters({ ...filters, search: '' });
  };

  /* eslint-disable react/prop-types */
  const renderPillow = ({
    name,
    icon,
    disabled,
    hidden,
  }) => (!hidden ? (
    <Grid item key={name}>
      <Pillow
        text={t(name)}
        icon={icon}
        active={dataType === name}
        disabled={disabled}
        onClick={selectPillow(name)}
      />
    </Grid>
  ) : null);
  /* eslint-enable react/prop-types */

  return (
    <Grid container className={container} spacing={2}>
      <Grid item container md={3} alignItems="center" className={title}>
        <Typography variant="h2" gutterBottom>{t('select-type')}</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          container
          justify="center"
          spacing={2}
        >
          {pillows.map(renderPillow)}
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <TopFilterSearch />
      </Grid>
    </Grid>
  );
};

export default TopFilters;
