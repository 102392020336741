import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import countBy from 'lodash/countBy';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useAppContext } from '../../contexts/AppContext';
import useGetNotifications from '../../graphql/hooks/notifications/useGetNotifications';
import MobileDrawer from '../../components/MobileDrawer';
import Button from '../../components/Button';
import QuickMenu from './QuickMenu';
import { ReactComponent as CaretDown } from '../../assets/icons/caretDown.svg';
import { ReactComponent as Times } from '../../assets/icons/times.svg';
import librawayLogoExtendedContrasting from '../../assets/librawayLogoExtendedContrasting.svg';
import useHeaderStyle from './style';

/**
 * Local style
 */
const useLocalStyles = makeStyles(({
  spacing,
  palette: { primary, secondary, white },
}) => ({
  drawerBodyContainer: {
    paddingTop: spacing(15),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
  },
  drawerPaper: {
    color: white.main,
    backgroundColor: primary.dark,
    overflowY: 'auto',
  },
  accordion: {
    backgroundColor: 'transparent',
    padding: `${spacing()}px ${spacing(2)}px ${spacing(3)}px`,
    '&:before': { height: 0 },
  },
  expansionSummary: { padding: 0 },
  expansionSummaryText: {
    fontWeight: 900,
    color: white.main,
  },
  expandIcon: { color: secondary.main },
  logoutButton: { margin: `${spacing(4)}px ${spacing(2)}px` },
}));

const useButtonStyles = makeStyles(({ spacing, palette: { white } }) => ({
  root: {
    width: '100%',
    padding: `${spacing()}px ${spacing(2)}px`,
    justifyContent: 'flex-start',
    color: white.main,
    fontSize: '1rem',
  },
  disabled: {
    opacity: 0.5,
  },
}));

/**
 * Component
 */
const HeaderDrawer = ({
  open,
  setClose,
  navigationItems,
  userItems,
  quickItems,
  languageItems,
}) => {
  const [expanded, setExpanded] = useState('navigation');
  const [scrolled, setScrolled] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation('header');
  const {
    authentication: [authentication, setAuthentication],
    user: [user, setUser],
  } = useAppContext();
  const { getNotifications, data } = useGetNotifications({ lazy: true });
  const unreadCount = data ? countBy(data, ({ node: { readAt } }) => readAt === null).true : 0;
  const authenticated = !!authentication.accessToken;
  const { palette: { primary, white } } = useTheme();
  const buttonStyle = useButtonStyles();
  const {
    appbar,
    container,
    logo,
    logoContainer,
    burgerMenu,
  } = useHeaderStyle();
  const {
    drawerBodyContainer,
    drawerPaper,
    accordion,
    expansionSummary,
    expansionSummaryText,
    expandIcon,
    logoutButton,
  } = useLocalStyles();

  const authenticationFilter = ({ justAuthenticated }) => (
    justAuthenticated === undefined
    || (authenticated && justAuthenticated)
    || (!authenticated && !justAuthenticated)
  );

  useEffect(() => {
    if (authenticated) {
      getNotifications();
    }
  }, [authenticated]);

  const closePanel = useCallback(() => {
    setExpanded(false);
    setClose();
  }, [setExpanded, setClose]);

  useEffect(() => history.listen(closePanel), [history, closePanel]);

  const handleChange = (panel) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDrawerScroll = ({ target: { scrollTop } }) => {
    setScrolled(scrollTop > 0);
  };

  const handleLanguageClick = (slug) => () => {
    i18n.changeLanguage(slug);
  };

  const handleLogout = () => {
    setAuthentication({});
    setUser({});
  };

  const routeTo = (route) => () => {
    history.push(`/${route}`);
  };

  // eslint-disable-next-line react/prop-types
  const renderListItem = ({ name, slug, onClick }) => (
    <ListItem
      key={name}
      button
      dense
      onClick={onClick}
    >
      <Typography>{slug ? name : t(name)}</Typography>
    </ListItem>
  );

  /* eslint-disable react/prop-types */
  const renderLinkListItem = ({
    link,
    name,
    disabled,
    badge,
  }) => (
    <Grid item key={link || name}>
      <ButtonBase
        onClick={routeTo(link || name)}
        classes={buttonStyle}
        disabled={disabled}
        style={{ padding: '10px 16px' }}
      >
        <Badge
          color="secondary"
          badgeContent={badge}
          invisible={!badge}
        >
          {t(name)}
        </Badge>
      </ButtonBase>
    </Grid>
  );

  return (
    <MobileDrawer
      open={open}
      onClose={setClose}
      classes={{ paper: drawerPaper }}
      onScroll={handleDrawerScroll}
    >
      <AppBar
        position="fixed"
        color="default"
        className={appbar}
        style={{ backgroundColor: primary.dark, width: 'inherit' }}
        elevation={scrolled ? 3 : 0}
      >
        <Container className={container}>
          <Toolbar>
            <Box className={logoContainer}>
              <img
                src={librawayLogoExtendedContrasting}
                alt="Libraway logo"
                className={logo}
              />
            </Box>
            <ButtonBase
              className={burgerMenu}
              onClick={setClose}
              style={{ color: white.main }}
            >
              <Times />
            </ButtonBase>
          </Toolbar>
        </Container>
      </AppBar>

      <Container className={drawerBodyContainer}>
        {authenticated ? (
          <List style={{ width: '100%' }}>
            {renderLinkListItem({
              name: 'notifications',
              badge: unreadCount,
            })}
          </List>
        ) : null}

        <Accordion
          expanded={expanded === 'navigation'}
          onChange={handleChange('navigation')}
          elevation={0}
          classes={{ root: accordion }}
        >
          <AccordionSummary
            expandIcon={<CaretDown />}
            classes={{ expandIcon, root: expansionSummary }}
          >
            <Typography classes={{ root: expansionSummaryText }}>
              {t('welcome-libraway')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction="column" spacing={1}>
              {navigationItems
                .filter(authenticationFilter)
                .map(renderLinkListItem)}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {authenticated ? (
          <Accordion
            expanded={expanded === 'user'}
            onChange={handleChange('user')}
            elevation={0}
            className={accordion}
          >
            <AccordionSummary
              expandIcon={<CaretDown />}
              classes={{ expandIcon, root: expansionSummary }}
            >
              <Typography className={expansionSummaryText}>
                {t('welcome', { name: user.name, context: user.gender ? user.gender.toLowerCase() : 'male' })}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ width: '100%' }}>
                {userItems.map(renderLinkListItem)}
              </List>
            </AccordionDetails>
          </Accordion>
        ) : null}

        {quickItems && <QuickMenu quickItems={quickItems} isMobileView />}

        <Button
          onClick={authenticated ? handleLogout : routeTo('signin')}
          filled
          className={logoutButton}
        >
          {t(authenticated ? 'logout' : 'login')}
        </Button>

        {false && (
          <List style={{ width: '100%' }}>
            {languageItems.map(({ name, slug }) => renderListItem({
              name,
              slug,
              onClick: handleLanguageClick(slug),
            }))}
          </List>
        )}
      </Container>
    </MobileDrawer>
  );
};

HeaderDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setClose: PropTypes.func.isRequired,
  navigationItems: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  userItems: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  quickItems: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
  })),
  languageItems: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
};

HeaderDrawer.defaultProps = {
  quickItems: null,
};

export default HeaderDrawer;
