import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ButtonBase from '@material-ui/core/ButtonBase';
import useSignout from '../../graphql/hooks/user/useSignout';
import Link from '../../components/Link';
import { ReactComponent as UserIcon } from '../../assets/icons/utente.svg';
import useStyles from './style';

const UserDropdown = ({ menuItems }) => {
  const { t } = useTranslation('header');
  const { iconButton } = useStyles();
  const history = useHistory();
  const logout = useSignout();
  const [anchorElement, setAnchorElement] = useState(null);

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  const handleClick = ({ currentTarget }) => {
    setAnchorElement(currentTarget);
  };

  const handleClose = useCallback(() => { setAnchorElement(null); }, [setAnchorElement]);

  useEffect(() => history.listen(handleClose), [history, handleClose]);

  /* eslint-disable react/prop-types */
  const renderLink = ({
    link,
    name,
    divider = false,
    disabled,
  }) => (
    disabled ? (
      <ListItem key={name} divider={divider} disabled>{t(name)}</ListItem>
    ) : (
      <Link key={link || name} to={`/${link || name}`}>
        <ListItem divider={divider}>{t(name)}</ListItem>
      </Link>
    )
  );
  /* eslint-enable react/prop-types */

  return (
    <>
      <ButtonBase
        disableRipple
        className={iconButton}
        onClick={handleClick}
      >
        <UserIcon width={20} height={30} />
      </ButtonBase>

      <Menu
        anchorEl={anchorElement}
        open={Boolean(anchorElement)}
        onClose={handleClose}
      >
        {menuItems.map(renderLink)}
        <ListItem onClick={handleLogout} style={{ cursor: 'pointer', outline: 'none' }}>
          <Typography variant="h6">{t('logout')}</Typography>
        </ListItem>
      </Menu>
    </>
  );
};

UserDropdown.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    name: PropTypes.string,
    divider: PropTypes.bool,
  })).isRequired,
};

export default UserDropdown;
