import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette: { secondary }, breakpoints }) => ({
  labelStyle: {
    paddingBottom: spacing(),
  },

  root: {
    border: `2px solid ${secondary.main}`,
    padding: ({ small }) => spacing(small ? 0.5 : 1, 3),
    borderRadius: 999,

    [breakpoints.down('xs')]: {
      '& select': {
        minHeight: 28,
      },
    },

    '& select:focus': {
      backgroundColor: 'transparent',
    },

    '& .MuiSvgIcon-root': {
      right: spacing(3),
    },
  },
}));

export default useStyles;
