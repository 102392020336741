import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { usePlanContext } from '../../contexts/PlanContext';
import LocalLoading from '../../components/LocalLoading';
// import Placeholder from '../../components/Placeholder';
import Recap from './Recap';
import TabsContainer from './TabsContainer';

const Content = () => {
  const { t } = useTranslation('dashboard');
  const history = useHistory();
  const [{ plan: { data } = {} }, { getPlanRecap }] = usePlanContext();

  useEffect(getPlanRecap, []);

  if (data === null) {
    history.push('/how-it-works');
  }
  // if (data === null) return <Placeholder text={t('no-plan-placeholder')} />;

  if (data) {
    return (
      <>
        <Recap />
        <TabsContainer />
      </>
    );
  }

  return <LocalLoading />;
};

export default Content;
