import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { useAppContext } from '../../contexts/AppContext';
import { white } from '../../theme/common/constants';
import { ReactComponent as LibrawayLogo } from '../../assets/librawayLogoExtendedVertical.svg';
// import InlineInputButton from '../../components/InlineInputButton';
import SocialAvatar from '../../components/SocialAvatar';
import Version from '../../components/Version';
import { navigationItems } from '../Header/items';
import { FooterContainer, useStyles } from './style';

const Footer = () => {
  const { t } = useTranslation(['footer', 'header']);
  const {
    logoGrid,
    logo,
    noHover,
    divider,
    copyright,
    terms,
    columnContainer,
    linkContainer,
    buttonDisabled,
  } = useStyles();
  const { authentication: [authentication] } = useAppContext();
  const isAuthenticated = !!authentication.accessToken;

  const authenticationFilter = ({ justAuthenticated }) => (
    justAuthenticated === undefined
    || (isAuthenticated && justAuthenticated)
    || (!isAuthenticated && !justAuthenticated)
  );

  const renderLinkItems = (items) => (
    items.map(({
      link,
      name,
      blank,
      disabled,
    }) => (
      <Grid
        key={link || name}
        item
        className={linkContainer}
      >
        <ButtonBase
          href={blank ? link || name : `/${link || name}`}
          target={blank ? '_blank' : '_self'}
          classes={{ disabled: buttonDisabled }}
          disabled={disabled}
        >
          {t(`header:${name}`)}
        </ButtonBase>
      </Grid>
    ))
  );

  return (
    <>
      <FooterContainer>
        <Container>
          <Grid container>
            <Grid
              item
              container
              xs={12}
              md={2}
              lg={2}
              justify="center"
              className={logoGrid}
            >
              <LibrawayLogo className={logo} />
            </Grid>

            <Grid
              item
              container
              className={columnContainer}
              xs={12}
              sm={3}
              direction="column"
            >
              {renderLinkItems(navigationItems.filter(authenticationFilter))}
              {/* {renderLinkItems(firstColumnItems)} */}
            </Grid>

            <Grid
              item
              container
              className={columnContainer}
              xs={12}
              sm={3}
              direction="column"
            />

            <Grid item container xs={12} sm={6} md={4} lg={4}>
              <List style={{ width: '100%', paddingTop: 0 }}>
                <ListItem className={noHover} style={{ paddingTop: 0 }}>{t('follow-us')}</ListItem>
                <ListItem className={noHover}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <SocialAvatar
                        social="facebook"
                        url="https://www.facebook.com/libraway2020/"
                      />
                    </Grid>
                    <Grid item>
                      <SocialAvatar
                        social="instagram"
                        url="https://www.instagram.com/_libraway_/"
                      />
                    </Grid>
                  </Grid>
                </ListItem>
                {/* <ListItem dense className={noHover}>{t('newsletter')}</ListItem>
                <ListItem dense className={noHover}>
                  <InlineInputButton />
                </ListItem> */}
              </List>
            </Grid>

            <Grid
              item
              container
              xs={12}
              classes={{ root: divider }}
            >
              <Grid
                item
                container
                xs={12}
                sm={8}
                classes={{ root: copyright }}
              >
                <Typography>
                  © 2020 Libraway. All rights reserved
                  &nbsp;
                  P.iva 04490500404
                  &nbsp;
                  <Version />
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={4}
                spacing={2}
                justify="flex-end"
                classes={{ root: terms }}
              >
                {/* <Grid item>
                  <ButtonBase
                    styles={{ color: white.hex }}
                    href="https://libraway.com/privacy"
                    disabled
                    target="_blank"
                  >
                    {t('terms')}
                  </ButtonBase>
                </Grid> */}
                <Grid item>
                  <ButtonBase
                    styles={{ color: white.hex }}
                    href="https://landing.libraway.com/privacy"
                    target="_blank"
                  >
                    {t('privacy')}
                  </ButtonBase>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </FooterContainer>
    </>
  );
};

export default Footer;
