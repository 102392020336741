import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ breakpoints }) => ({
  paper: {
    width: 400,
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export default useStyles;
