import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { getImagesSet, formatMoney } from '../../libraries/helpers';
import Link from '../Link';
import Quantity from '../Quantity';
import useStyles from './style';

const CartItem = ({
  id,
  dishId,
  quantity,
  notEditable,
  removeItem,
  unitPrice,
  title,
  grams,
  updateQuantityCart,
  voidSpacesLeft,
  image,
}) => {
  const { t } = useTranslation('ingredients');
  const {
    itemContainer,
    titleItem,
    priceStyle,
    trashButton,
    previewImage,
    sizePrice,
  } = useStyles();

  const getDropdowItem = (_, index) => ({ label: index + 1, value: index + 1 });

  const handleRemove = () => { removeItem({ id }); };

  const handleChange = (newQuantity) => { updateQuantityCart({ id, quantity: newQuantity }); };

  return (
    <Grid
      container
      spacing={1}
      className={itemContainer}
      alignItems="center"
      justify="space-between"
    >
      {!notEditable && (
        <Grid item>
          <ButtonBase className={trashButton} onClick={handleRemove}>
            <FontAwesomeIcon size="lg" icon={faTrashAlt} />
          </ButtonBase>
        </Grid>
      )}

      <Grid item>
        {image ? (
          <Link to={`/dishes/${dishId}`}>
            <img
              alt={title}
              src={image.thumbnail}
              srcSet={getImagesSet(image, 'thumbnail')}
              className={previewImage}
            />
          </Link>
        ) : null}
      </Grid>

      <Grid item xs={5} md>
        <Grid container direction="column" justify="center">
          <Grid item>
            <Link to={`/dishes/${dishId}`} underline>
              <Typography className={titleItem}>
                {title}
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography className={sizePrice}>
                  {`${grams} ${t('gram', { count: grams })} | `}
                  <span className={priceStyle}>{formatMoney(unitPrice * quantity)}</span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        {!notEditable ? (
          <Quantity
            quantity={quantity}
            items={
              [...new Array(Math.min(10, voidSpacesLeft + quantity))]
                .map(getDropdowItem)
            }
            voidSpacesLeft={!!voidSpacesLeft}
            onChange={handleChange}
          />
        ) : <Typography align="center">{`x ${quantity}`}</Typography>}
      </Grid>
    </Grid>
  );
};

CartItem.propTypes = {
  id: PropTypes.string,
  dishId: PropTypes.string,
  title: PropTypes.string.isRequired,
  grams: PropTypes.number,
  unitPrice: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  voidSpacesLeft: PropTypes.number,
  updateQuantityCart: PropTypes.func,
  removeItem: PropTypes.func,
  notEditable: PropTypes.bool,
  image: PropTypes.shape(),
};

CartItem.defaultProps = {
  id: '',
  dishId: '',
  grams: null,
  voidSpacesLeft: 0,
  updateQuantityCart: () => { },
  removeItem: () => { },
  notEditable: false,
  image: null,
};

export default CartItem;
