import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useCartContext } from '../../../contexts/CartContext';
import CartItem from '../../CartItem';

const QuickCart = () => {
  const { palette: { grey } } = useTheme();
  const [
    { cartItems, voidSpacesLeft },
    { removeItem, updateQuantityCart },
  ] = useCartContext();

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
    >
      {cartItems.map(({
        id,
        dishId,
        title,
        grams,
        unitPrice,
        quantity,
        image,
      }, index) => (
        <>
          <Grid item xs={5}>
            <CartItem
              key={id}
              id={id}
              dishId={dishId}
              title={title}
              grams={grams}
              unitPrice={unitPrice}
              quantity={quantity}
              voidSpacesLeft={voidSpacesLeft}
              updateQuantityCart={updateQuantityCart}
              removeItem={removeItem}
              image={image}
            />
          </Grid>
          {cartItems[index + 1] && index % 2 === 0 && (
            <Grid item style={{ justifySelf: 'center' }}>
              <div
                style={{
                  height: '80%',
                  borderLeft: `2px solid ${grey.main}`,
                }}
              />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default QuickCart;
