/* eslint-disable no-param-reassign */
const tiledeskInit = ({
  accessToken,
  flag,
  history,
  tiledeskJwt,
}) => () => {
  window.tiledesk.on('onLoadParams', () => {
    if (accessToken) {
      window.tiledesk.signInWithCustomToken(`JWT ${tiledeskJwt}`);
    } else {
      window.tiledesk.signInAnonymous();
    }
  });

  window.tiledesk.on('onAuthStateChanged', ({ detail: { isLogged } }) => {
    if (!isLogged && !flag.current) {
      if (window.tiledesk.reInit) {
        window.tiledesk.reInit();
      }
      flag.current = true;
    } else {
      flag.current = false;
      if (window.tiledesk.show) {
        window.tiledesk.show();
      }
    }
  });

  window.tiledesk.on('onBeforeMessageSend', ({ detail: { attributes: { action } } }) => {
    if (action) {
      const { command, value } = action;

      switch (command) {
        case 'router.go':
          history.push(value);
          break;

        default: break;
      }
    }
  });
};

export default tiledeskInit;
