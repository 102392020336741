import React, { Suspense } from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { AppProvider } from './contexts/AppContext';
import App from './App';
import GlobalLoading from './components/GlobalLoading';
import ScrollToTop from './components/ScrollToTop';
import * as serviceWorker from './serviceWorker';

import librawayTheme from './theme/main';
import './libraries/i18n';

const {
  // NODE_ENV,
  // REACT_APP_LIBRAWAY_VERSION,
  REACT_APP_GTM_ID,
  REACT_APP_TILEDESK_PROJECT_ID,
} = process.env;

// Sentry.init({
//   dsn: 'https://037c7f830fd94d05aa66d0dce9c2c5f2@log.appfactory.it/4',
//   autoSessionTracking: true,
//   environment: NODE_ENV,
//   debug: NODE_ENV === 'development',
//   release: `libraway@${REACT_APP_LIBRAWAY_VERSION}`,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

TagManager.initialize({ gtmId: REACT_APP_GTM_ID });

window.tiledeskSettings = {
  projectid: REACT_APP_TILEDESK_PROJECT_ID,
  widgetTitle: 'Libraway',
  showWaitTime: false,
  showAvailableAgents: false,
  autoStart: false,
};
(function (d, s, id) {
  const fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  const js = d.createElement(s);
  js.id = id;
  js.src = 'https://widget.tiledesk.com/v4/launch.js';
  fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'tiledesk-jssdk'));

render(
  <BrowserRouter>
    <ScrollToTop />
    <ThemeProvider theme={librawayTheme}>
      <CssBaseline />
      <Suspense fallback={<GlobalLoading />}>
        <AppProvider>
          <App />
        </AppProvider>
      </Suspense>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.register();
