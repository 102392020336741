import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useHomepageDishesQuery from '../../graphql/hooks/dishes/useGetHomepageDishes';
import LocalLoading from '../../components/LocalLoading';
import Card from '../../components/Card';
import Button from '../../components/Button';
import useStyles from './style';

const Dishes = () => {
  const { t } = useTranslation('home');
  const history = useHistory();
  const { breakpoints } = useTheme();
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const { loading, data } = useHomepageDishesQuery();
  const { dishesContainer, mobileCarousel } = useStyles();

  const handleCardClick = (id) => () => { history.push(`/dishes/${id}`); };
  const handleButtonClick = () => { history.push('/dishes'); };

  /* eslint-disable react/prop-types */
  const renderCard = ({
    node: {
      id,
      title,
      image,
      price,
      discountedPrice,
      comments,
      avgVote,
      sizes,
      foods,
    },
  }) => {
    const { total: commentsCount } = (comments && comments.pageInfo) || {};

    return (
      <Grid
        key={id}
        item
        container
        justify="center"
        xs={12}
        sm={6}
        md={3}
      >
        <Card
          badge={discountedPrice ? {
            label: t('badge-sale'),
            color: 'primary.dark',
          } : {}}
          cartInfo={{
            id,
            title,
            price,
            discountedPrice,
            sizes,
            image,
          }}
          title={title}
          type="dishes"
          image={image}
          commentsCount={commentsCount}
          valuation={avgVote}
          foods={foods}
          handleClick={handleCardClick(id)}
        />
      </Grid>
    );
  };
  /* eslint-enable react/prop-types */

  if (loading) return <LocalLoading />;

  return (
    <div className={dishesContainer}>
      <Container>
        {!isSmallView ? (
          <Grid container spacing={4}>
            {data && Array.isArray(data) && data.map(renderCard)}
          </Grid>
        ) : (
          <div className={mobileCarousel}>
            {data && Array.isArray(data) && data.map(renderCard)}
          </div>
        )}

        <Grid container justify="center">
          <Button filled onClick={handleButtonClick}>
            {t('show-menu')}
          </Button>
        </Grid>
      </Container>
    </div>
  );
};

export default Dishes;
