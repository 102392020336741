import { useState, useEffect } from 'react';
import { librawayStorage } from '../libraries/helpers';

const useAppState = () => {
  const headerContrasted = useState(false);
  const authentication = useState(librawayStorage.get('authentication') || { accessToken: null, tokenType: null });
  const user = useState(librawayStorage.get('user') || {});
  const orderDishesFilters = useState({ dataType: 'recipes', search: '' });
  const authenticated = !!authentication[0].accessToken;

  useEffect(() => {
    librawayStorage.set('authentication', authentication[0]);
    librawayStorage.set('user', user[0]);
  }, [authentication, user]);

  return {
    authenticated,
    authentication,
    headerContrasted,
    orderDishesFilters,
    user,
  };
};

export default useAppState;
