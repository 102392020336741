import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import usePreferredRecipesQuery from '../../../graphql/hooks/recipes/useGetPreferredRecipesQuery';
import { DataContext } from '../contexts';
import LocalLoading from '../../../components/LocalLoading';
import Card from '../../../components/Card';
import Carousel from './Carousel';
import { ReactComponent as PreferredRecipeIcon } from '../../../assets/icons/preferredRecipe.svg';
import useStyles from './style';
import 'react-awesome-slider/dist/styles.css';

const CommunityPreferred = () => {
  const { t } = useTranslation('orderDishes');
  const history = useHistory();
  const { breakpoints } = useTheme();
  const { dataType } = useContext(DataContext);
  const isSmallView = useMediaQuery(breakpoints.down('sm'));
  const recipesLimit = 12;
  const { loading, data = [] } = usePreferredRecipesQuery({ recipesLimit });
  const {
    icon,
    preferredContainer,
    titleContainer,
    mobileCarousel,
  } = useStyles();

  const handleCardClick = (id) => () => { history.push(`/${dataType}/${id}`); };

  /* eslint-disable react/prop-types */
  const renderCard = ({
    node: {
      title,
      image,
      commentsCount,
      avgVote,
      foods,
      id,
    },
  }) => (
    <Card
      key={id}
      title={title}
      type={dataType}
      image={image}
      foods={foods}
      commentsCount={commentsCount}
      valuation={avgVote}
      handleClick={handleCardClick(id)}
    />
  );
  /* eslint-enable react/prop-types */

  return (
    <Grid container spacing={4}>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        className={titleContainer}
      >
        <PreferredRecipeIcon className={icon} />
        <Typography variant="h2" gutterBottom>{t('community-preferred')}</Typography>
      </Grid>

      {!isSmallView ? (
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          className={preferredContainer}
        >
          <Carousel
            loading={loading}
            data={data}
            handleCardClick={handleCardClick}
          />
        </Grid>
      ) : (
        <div className={mobileCarousel}>
          {loading
            ? <LocalLoading />
            : data.map(renderCard)}
        </div>
      )}
    </Grid>
  );
};

export default CommunityPreferred;
