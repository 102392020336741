import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useWindowScrollPosition from '@rehooks/window-scroll-position';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { languages } from '../../libraries/helpers';
import { useAppContext } from '../../contexts/AppContext';
import TopRightMenu from './TopRightMenu';
import MobileDrawer from './HeaderDrawer';
import MenuItem from './MenuItem';

import useStyles from './style';

import { ReactComponent as CartIcon } from '../../assets/icons/carrello.svg';
import { ReactComponent as BurgerMenu } from '../../assets/icons/burgerMenu.svg';
import { ReactComponent as BurgerMenuWhite } from '../../assets/icons/burgerMenuWhite.svg';
import librawayLogo from '../../assets/librawayLogo.svg';
import librawayLogoContrasting from '../../assets/librawayLogoContrasting.svg';

import { navigationItems, userItems } from './items';

/**
 * Component
 */
const Header = ({ contrasted, whiteBackground }) => {
  const [drawerOpened, setDrawerOpened] = useState(false);
  const { spacing, breakpoints } = useTheme();
  const history = useHistory();
  const isMobileView = useMediaQuery(breakpoints.down('sm'));
  const position = useWindowScrollPosition({ throttle: 0 });
  const shrink = position.y >= spacing(5.5);
  const {
    appbar,
    burgerMenu,
    container,
    logoContainer,
    logo,
    menuItemsContainer,
  } = useStyles({ shrink, contrasted, whiteBackground });
  const { authentication: [authentication] } = useAppContext();
  const isAuthenticated = !!authentication.accessToken;

  const getLogo = () => {
    if (shrink) return librawayLogo;
    if (contrasted) return librawayLogoContrasting;

    return librawayLogo;
  };

  const getBurgerIcon = () => {
    if (shrink) return <BurgerMenu />;
    if (contrasted) return <BurgerMenuWhite />;

    return <BurgerMenu />;
  };

  const getAppbarHeight = () => {
    if (shrink) {
      if (isMobileView) return spacing(8);
      return spacing(10);
    }

    return null;
  };

  const handleDrawerOpen = (command) => () => {
    setDrawerOpened(command !== 'undefined' ? command : !drawerOpened);
  };

  const authenticationFilter = ({ justAuthenticated }) => (
    justAuthenticated === undefined
    || (isAuthenticated && justAuthenticated)
    || (!isAuthenticated && !justAuthenticated)
  );

  /**
   * Render normal wide navigation items
   */
  const renderWideNavigation = () => (
    <Grid container alignItems="center" justify="space-between">
      <Grid item>
        <Grid container alignItems="center" className={menuItemsContainer}>
          {navigationItems
            .filter(authenticationFilter)
            .filter(({ justFooter }) => !justFooter)
            .map(({
              link,
              name,
              icon,
              disabled,
            }) => (
              <Grid key={link || name} item>
                <MenuItem
                  link={link}
                  name={name}
                  icon={icon}
                  disabled={disabled}
                  contrasted={contrasted}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>

      <Grid item>
        <TopRightMenu />
      </Grid>
    </Grid>
  );

  /**
   * Render burger menu icon to mobile view
   */
  const renderBurgerNavigation = () => (
    <>
      <ButtonBase
        disableRipple
        onClick={() => { history.push('checkout'); }}
        style={{ marginRight: 20 }}
      >
        <CartIcon width={20} height={30} />
      </ButtonBase>
      <Button
        className={burgerMenu}
        onClick={handleDrawerOpen(true)}
      >
        {getBurgerIcon()}
      </Button>
    </>
  );

  return (
    <>
      {isMobileView && (
        <MobileDrawer
          open={drawerOpened}
          setClose={handleDrawerOpen(false)}
          navigationItems={navigationItems}
          userItems={userItems}
          languageItems={languages}
        />
      )}

      <AppBar
        position="fixed"
        color="default"
        className={appbar}
        elevation={0}
        style={{ height: getAppbarHeight() }}
      >
        <Container className={container}>
          <Toolbar style={{ padding: 0 }}>
            <RouterLink to="/" className={logoContainer}>
              <img
                src={getLogo()}
                alt="Libraway logo"
                className={logo}
                style={{ height: shrink && 40 }}
              />
            </RouterLink>

            {!isMobileView
              ? renderWideNavigation()
              : renderBurgerNavigation()}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  contrasted: PropTypes.bool,
  whiteBackground: PropTypes.bool,
};

Header.defaultProps = {
  contrasted: false,
  whiteBackground: false,
};

export default Header;
